<template>
  <div :style="`background-color: ${default_.color.main}; color: white`">
    <v-container dark class="container-main">
      <div class="footer">
        <v-row>
          <v-col cols="12" md="5">
            <v-img
              width="200"
              src="../assets/Images/LOGO.png"
              alt="nftgame"
              loading="lazy"
              decoding="async"
            ></v-img>
            <div class="text mt-2">
              Sàn giao dịch Token Gamefi, Airdrop, NFT, Crypto... an toàn và
              nhanh chóng.
            </div>
            <div class="mt-2">
              <v-btn icon v-for="(item, i) in socials" :key="i">
                <v-icon color="white">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" md="7" class="menus-f">
            <v-row>
              <v-col cols="4" v-for="(item, i) in menus" :key="i">
                <div>{{ item.name }}</div>
                <div>
                  <ul>
                    <li v-for="(jtem, j) in item.items" :key="j">
                      <div class="menu_footer" @click="jtem.name == 'Game Mining' ? go(jtem.to) : to(jtem.to)">
                        {{ jtem.name }}
                      </div>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["default_", "to"],
  methods: {
    go(to: any) {
      window.location.href = to
    }
  },
  data: () => ({
    socials: [
      {
        name: "",
        icon: "fi-brands-facebook",
        href: "#",
      },
      {
        name: "",
        icon: "fi fi-brands-twitter",
        href: "#",
      },
      {
        name: "",
        icon: "fi-brands-discord",
        href: "#",
      },
      {
        name: "",
        icon: "fi-brands-instagram",
        href: "#",
      },
      {
        name: "",
        icon: "fi-brands-tik-tok",
        href: "#",
      },
    ],
    menus: [
      {
        name: "NFTG Exchange",
        items: [
          {
            name: "P2P mua bán",
            to: "/marketplace",
          },
          {
            name: "Nhà môi giới",
            to: "/broker/list",
          },
          {
            name: "Đăng ký làm nhà môi giới",
            to: "/broker",
          },
          {
            name: "NFT của tôi",
            to: "/account/nfts/Ox?chain=ethereum",
          },
          {
            name: "Airdrop",
            to: "/airdrop",
          },
          {
            name: "Bộ sưu tập",
            to: "/game",
          },
        ],
      },
      {
        name: "Hệ sinh thái",
        items: [
          {
            name: "Game Mining",
            to: "https://gamenftg.nftgexchange.com",
          },
          {
            name: "Art",
            to: "/",
          },
          {
            name: "Music",
            to: "/",
          },
          {
            name: "Domain Name",
            to: "/",
          },
          {
            name: "Collectibles",
            to: "/",
          },
          {
            name: "Virtual World",
            to: "/",
          },
        ],
      },
      {
        name: "Marketplace",
        items: [
          {
            name: "All NFTs",
            to: "/",
          },
          {
            name: "Art",
            to: "/",
          },
          {
            name: "Music",
            to: "/",
          },
          {
            name: "Domain Name",
            to: "/",
          },
          {
            name: "Collectibles",
            to: "/",
          },
          {
            name: "Virtual World",
            to: "/",
          },
        ],
      },
    ],
  }),
});
</script>

<style scoped lang="scss">
.footer {
  padding: 37px 0px;

  .text {
    opacity: 0.7;
  }

  .menus-f {
    ul li {
      list-style: none;
      opacity: 0.6;
    }
  }
}

.v-application ul,
.v-application ol {
  padding-left: 0px;
}

.menu_footer {
  cursor: pointer;
  &:hover {
    transition: 1s;
    background: linear-gradient(
      270deg,
      #12b883,
      #8cfff5 17.47%,
      #dafdff 39.54%,
      #a8fb70 60.49%,
      #c3b1fe 84.44%,
      #2167e8
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
