<template>
  <div style="font-size: 16px">
    <v-tabs style="border-radius: 10px 10px 0px 10px" v-model="tabSend">
      <v-tab style="font-size: 14px" class="text-none" @click="tab(0)">
        <v-icon size="15" left> fi-sr-transformation-circle </v-icon>
        Rút tiền
      </v-tab>
      <v-tab style="font-size: 14px" class="text-none" @click="tab(1)">
        <v-icon size="15" left> fi-rr-arrows-repeat</v-icon>
        Chuyển tiền
      </v-tab>

      <v-tab-item>
        <v-card flat :color="default_.color.main" dark>
          <v-row class="pa-2">
            <v-col cols="12">
              <div style="padding: 20px 0px; margin-top: -10px">
                Khả dụng:
                <b class="text_th">{{ formatCryptoNumber(symbolFrom.my) }}</b>
                {{ symbolFrom.symbol }}
              </div>
            </v-col>
            <v-col cols="12" style="margin-top: -30px">
              <v-text-field
                v-if="symbolFrom.symbol !== main.symbol"
                placeholder="Nhập địa chỉ của người nhận"
                label="Địa chỉ"
                hint="Vui lòng nhập địa chỉ rút tiền hỗ trợ theo mạng lưới. Bạn có khả năng mất tài sản nếu nhập nhầm ví hoặc sai địa chỉ ví."
                persistent-hint
                outlined
                persistent-placeholder
                v-model="address"
              ></v-text-field>

              <div v-else>
                <v-combobox
                  persistent-placeholder
                  v-if="myPayments.length > 0"
                  :items="myPayments"
                  :item-text="combineFields"
                  placeholder="Nhập phương thức thanh toán"
                  label="Phương thức thanh toán"
                  hint="Chọn ít nhất một phương thức thanh toán, khi lệnh được duyệt tiền tự đông về tài khoản của bạn"
                  persistent-hint
                  outlined
                  v-model="address"
                ></v-combobox>

                <v-sheet v-else style="border-radius: 10px; font-size: 14px">
                  <div class="pa-4 text-center">
                    Vui lòng thêm phương thức thanh toán để được rút
                    {{ main.symbol }}.
                  </div>
                </v-sheet>
              </div>
            </v-col>

            <v-col :cols="symbolFrom.symbol == main.symbol ? 12 : 6">
              <v-sheet
                class="card-main"
                style="border-radius: 10px; cursor: pointer"
                @click="chooseToken()"
              >
                <div class="pa-2">
                  <div class="d-flex">
                    <div>
                      <v-avatar size="35" class="mt-1">
                        <v-img
                          :alt="symbolFrom.symbol"
                          loading="lazy"
                          decoding="async"
                          :src="symbolFrom.image"
                        ></v-img>
                      </v-avatar>
                    </div>

                    <div class="ml-2">
                      <div style="font-size: 16px">
                        <div class="d-flex">
                          <div class="font-weight-bold">
                            {{ symbolFrom.symbol }}
                          </div>
                          <div>
                            <v-icon
                              size="18"
                              class="ml-2"
                              style="margin-top: -7px"
                              >fi-rr-caret-down</v-icon
                            >
                          </div>
                        </div>
                      </div>
                      <div style="font-size: 14px">
                        <span v-if="chain?._id">Tiền</span>

                        <span v-else>Không hoạt động</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-sheet>
            </v-col>

            <v-col cols="6" v-if="symbolFrom.symbol != main.symbol">
              <v-skeleton-loader
                v-if="isLoadingSend"
                class="mx-auto"
                height="60"
                style="border-radius: 10px"
                type="image"
              ></v-skeleton-loader>
              <v-sheet
                v-else
                class="card-main"
                style="border-radius: 10px; cursor: pointer"
                @click="isChain ? null : chooseChain()"
              >
                <div class="pa-2" v-if="chain?._id">
                  <div class="d-flex">
                    <div>
                      <v-avatar size="35" class="mt-1">
                        <v-img
                          :alt="chain.chain_coin.name"
                          loading="lazy"
                          decoding="async"
                          :src="chain.chain_coin.image"
                        ></v-img>
                      </v-avatar>
                    </div>

                    <div class="ml-2">
                      <div style="font-size: 14px">
                        <div class="d-flex">
                          <div class="font-weight-bold">
                            {{ chain.chain_coin.name }}
                          </div>
                          <div v-if="!isChain">
                            <v-icon
                              size="18"
                              class="ml-2"
                              style="margin-top: -7px"
                              >fi-rr-caret-down</v-icon
                            >
                          </div>
                        </div>
                      </div>
                      <div style="font-size: 14px">Mạng</div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <v-sheet style="border-radius: 10px; font-size: 12px">
                    <div class="pa-3">
                      Hiện tại
                      <span class="text_th">{{ symbolFrom.symbol }}</span> không
                      thể rút. Vui lòng quay lại sau.
                    </div>
                  </v-sheet>
                </div>
              </v-sheet>
            </v-col>

            <v-col cols="12">
              <v-text-field
                :placeholder="`Số lượng ${symbolFrom.symbol} rút`"
                hide-details=""
                :label="`Số lượng ${symbolFrom.symbol} rút`"
                outlined
                type="number"
                hide-spin-buttons
                persistent-placeholder
                v-model="amount"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-sheet
                color="rgb(19 19 19)"
                style="border-radius: 10px; border: 1px solid #1e1e1e"
              >
                <div class="pa-3" style="font-size: 14px">
                  <v-row no-gutters>
                    <v-col cols="6" style="opacity: 0.8"> Số tiền nhận </v-col>

                    <v-col cols="6" class="text-end" style="opacity: 0.8">
                      Phí mạng lưới
                    </v-col>
                    <v-col cols="6" class="font-weight-bold">
                      <div v-if="amount">
                        <b
                          style="font-size: 20px"
                          v-if="symbolFrom.symbol == main.symbol"
                        >
                          {{
                            (
                              (this.amount ? this.amount : 0) -
                              (1 / symbolFrom.price) * fee?.widthdraw
                            ).toFixed(8)
                          }}
                        </b>

                        <b style="font-size: 20px" v-else>
                          {{
                            (
                              this.amount -
                              (1 / symbolFrom.price) *
                                chain?.chain_coin?.feeWithdraw
                            ).toFixed(8)
                          }}
                        </b>
                        {{ symbolFrom.symbol }}
                      </div>
                      <div v-else>
                        <b style="font-size: 20px"> 0 </b>
                        {{ symbolFrom.symbol }}
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-end mt-2">
                      {{
                        (symbolFrom.symbol == main.symbol
                          ? (1 / symbolFrom.price) * fee?.widthdraw
                          : (1 / symbolFrom.price) *
                            chain?.chain_coin?.feeWithdraw
                        ).toFixed(8)
                      }}

                      {{ symbolFrom.symbol }}
                    </v-col>
                  </v-row>
                </div>
              </v-sheet>
            </v-col>

            <v-col cols="12" style="margin-top: -15px">
              <v-sheet
                class="square xyz-in"
                xyz="fade flip-up front-5"
                style="border-radius: 10px"
                v-if="error"
              >
                <v-row no-gutters class="mt-2">
                  <v-col cols="12">
                    <div class="pa-3 error--text">{{ error }}</div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>

            <v-col cols="12" style="margin-top: -10px">
              <v-btn
                class="bg_main_main_blue"
                style="border-radius: 10px"
                :disabled="!chain?._id || (symbolFrom.my > 0 ? false : true)"
                block
                x-large
                depressed
                @click="withdraw()"
                v-if="!isLoading"
              >
                {{
                  symbolFrom.my > 0
                    ? "Rút tiền"
                    : "Không đủ " + symbolFrom.symbol
                }}
              </v-btn>

              <v-btn
                v-else
                style="border-radius: 10px"
                block
                disabled
                x-large
                depressed
              >
                Đang xử lý
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat :color="default_.color.main" dark>
          <v-row class="pa-2">
            <v-col cols="12">
              <div style="padding: 20px 0px; margin-top: -10px">
                Khả dụng:
                <b class="text_th">{{ formatCryptoNumber(symbolFrom.my) }}</b>
                {{ symbolFrom.symbol }}
              </div>
            </v-col>
            <v-col cols="12" style="margin-top: -30px">
              <v-text-field
                placeholder="Nhập email"
                hide-details=""
                persistent-placeholder
                hint="Nhập email tài khoản đã có đăng ký ở sàn."
                persistent-hint
                outlined
                label="Email"
                type="email"
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-sheet
                class="card-main"
                style="border-radius: 10px; cursor: pointer"
                @click="chooseToken()"
              >
                <div class="pa-2">
                  <div class="d-flex">
                    <div>
                      <v-avatar size="35" class="mt-1">
                        <v-img
                          :alt="symbolFrom.symbol"
                          loading="lazy"
                          decoding="async"
                          :src="symbolFrom.image"
                        ></v-img>
                      </v-avatar>
                    </div>

                    <div class="ml-2">
                      <div style="font-size: 16px">
                        <div class="d-flex">
                          <div>{{ symbolFrom.symbol }}</div>
                          <div>
                            <v-icon
                              size="18"
                              class="ml-2"
                              style="margin-top: -7px"
                              >fi-rr-caret-down</v-icon
                            >
                          </div>
                        </div>
                      </div>
                      <div style="font-size: 14px">
                        Số dư: {{ formatCryptoNumber(symbolFrom.my) }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-sheet>
            </v-col>

            <v-col cols="12">
              <v-text-field
                placeholder="Nhập số lượng"
                hide-details=""
                label="Số lượng"
                outlined
                hide-spin-buttons
                type="number"
                v-model="amount"
                persistent-placeholder
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-sheet
                color="rgb(19 19 19)"
                style="border-radius: 10px; border: 1px solid #1e1e1e"
              >
                <div class="pa-3" style="font-size: 14px">
                  <v-row no-gutters>
                    <v-col cols="6" style="opacity: 0.8"> Bạn chuyển </v-col>

                    <v-col cols="6" class="text-end" style="opacity: 0.8">
                      Tài khoản nhận
                    </v-col>
                    <v-col cols="6" class="font-weight-bold">
                      <div v-if="amount">
                        <b style="font-size: 20px">
                          {{ formatCryptoNumber(amount) }}
                        </b>
                        {{ symbolFrom.symbol }}
                      </div>
                      <div v-else>
                        <b style="font-size: 20px"> 0 </b>
                        {{ symbolFrom.symbol }}
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-end mt-2">
                      {{
                        fee.tranfers
                          ? formatCryptoNumber(
                              amount - amount * (fee.tranfers / 100)
                            )
                          : formatCryptoNumber(amount)
                      }}

                      {{ symbolFrom.symbol }}
                    </v-col>
                  </v-row>
                </div>
              </v-sheet>
            </v-col>

            <v-col cols="12" style="margin-top: -15px">
              <v-sheet
                class="square xyz-in"
                xyz="fade flip-up front-5"
                style="border-radius: 10px"
                v-if="error"
              >
                <v-row no-gutters class="mt-2">
                  <v-col cols="12">
                    <div class="pa-3 error--text">{{ error }}</div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>

            <v-col cols="12" style="margin-top: -10px">
              <div class="mt-2">
                <v-btn
                  class="bg_main_main_blue"
                  style="border-radius: 10px"
                  :disabled="symbolFrom.my > 0 ? false : true"
                  block
                  x-large
                  depressed
                  @click="transfers()"
                  v-if="!isLoading"
                >
                  {{
                    symbolFrom.my > 0
                      ? "Chuyển tiền"
                      : "Không đủ " + symbolFrom.symbol
                  }}
                </v-btn>

                <v-btn
                  v-else
                  style="border-radius: 10px"
                  block
                  disabled
                  x-large
                  depressed
                >
                  Đang xử lý
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <!-- <div style="padding: 0px 20px; margin-top: -15px">
      <v-row class="mt-2">
        <v-col cols="12" class="square xyz-in" xyz="fade down flip-up">
          <div><b>- Rút tiền:</b> Rút tiền ra địa chỉ ví bất kỳ.</div>
          <div>
            <b>- Chuyển tiền:</b> Chuyển tiền đến email / tài khoản có trên sàn.
          </div>

          <div class="error--text">
            Lưu ý: Sau khi lệnh được gửi, nếu gửi sai địa chỉ ví có thể khiến
            mất tiền, vui lòng kiểm tra kỹ trước khi giao dịch.
          </div>
        </v-col>
      </v-row>
    </div> -->
  </div>
</template>

<script lang="ts">
import WalletApi from "@/api/wallet";
import Vue from "vue";
import TotalComponent from "@/components/Total.vue";
import AuthApi from "@/api/auth";

export default Vue.extend({
  props: [
    "default_",
    "coins",
    "loadingAnim",
    "main",
    "formatPrice",
    "symbolFrom",
    "symbolTo",
    "fee",
    "chains",
    "chain",
    "isLoadingSend",
    "isChain",
    "tabSend",
    "formatCryptoNumber",
    "formatNumber",
  ],
  components: {
    TotalComponent,
  },
  data: () => ({
    error: "",
    amount: "" as any,
    returnFee: 0 as any,
    address: "" as any,
    isLoading: false,
    email: "",
    isLoadingMyPayment: false,
    myPayments: [] as any,
    paymentId: "",
  }),
  created() {
    if (this.symbolFrom.symbol == this.main.symbol) {
      this.loadMyPayment();
    }
  },
  methods: {
    total() {},
    combineFields(item: any) {
      return `${item.payment.name} - ${item.numberAccount} - ${item.fullname}`;
    },
    async loadMyPayment() {
      this.isLoadingMyPayment = true;
      const call = await AuthApi.myPayment();
      if (call.success) {
        this.myPayments = call.data;
      }
      this.isLoadingMyPayment = false;
    },
    async errorFunction(message: string) {
      this.error = message;
      setTimeout(() => {
        this.error = "";
      }, 2000);
    },
    async checkEerrorWithdraw() {
      try {
        if (this.amount <= 0) {
          this.errorFunction("Vui lòng nhấp số lượng.");
          return false;
        }

        if (this.symbolFrom.symbol !== this.main.symbol) {
          if (!this.address) {
            this.errorFunction("Vui lòng nhập ví.");
            return false;
          }
        } else {
          if (!this.address?._id) {
            this.errorFunction("Vui lòng nhập phương thức thanh toán.");
            return false;
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    },
    async checkEerrorTransfers() {
      try {
        if (this.amount <= 0) {
          this.errorFunction("Vui lòng nhập số lượng.");
          return false;
        }

        if (!this.email) {
          this.errorFunction("Vui lòng nhập email.");
          return false;
        }

        return true;
      } catch (error) {
        return false;
      }
    },
    async withdraw() {
      this.isLoading = true;

      const valid = await this.checkEerrorWithdraw();
      if (valid) {
        const res = await WalletApi.withdraw(
          this.symbolFrom._id,
          this.chain.chain_coin._id,
          this.amount,
          this.symbolFrom.symbol == this.main.symbol
            ? this.address._id
            : this.address
        );

        if (res.success) {
          this.$emit("tabEmit", "notification");

          const date = new Date(Date.now());
          this.$emit("withdrawChangeEmit", {
            date,
            symbol: this.symbolFrom.symbol,
            amount: this.amount,
            fee: this.returnFee,
          });
        } else {
          this.errorFunction(res.message);
        }
      }
      this.isLoading = false;
    },
    async transfers() {
      this.isLoading = true;

      const valid = await this.checkEerrorTransfers();
      if (valid) {
        const res = await WalletApi.transfers(
          this.symbolFrom._id,
          this.amount,
          this.email
        );

        if (res.success) {
          this.$emit("tabEmit", "notification");

          const date = new Date(Date.now());
          this.$emit("transfersChangeEmit", {
            date,
            symbol: this.symbolFrom.symbol,
            amount: this.amount,
            fee: this.returnFee,
          });
        } else {
          this.error = res.message;
        }
      }

      this.isLoading = false;
    },
    async chooseToken() {
      this.$emit("tabEmit", "chooseToken_send");
    },
    async chooseChain() {
      this.$emit("tabEmit", "chooseChain_send");
    },
    async tab(tab: number) {
      this.$emit("tabSendEmit", tab);
      this.error = "";
    },
    priteMain(price: any) {
      return (price.price / this.main.price) * price.my;
    },
  },
  watch: {
    amount() {
      if (this.amount < 0) {
        this.amount = 0;
      }
    },
  },
});
</script>

<style>
.theme--light.v-tabs-items {
  background-color: #131313;
}
</style>
