<template>
  <v-card :color="default_.color.main" dark class="scroll-notification">
    <div v-if="isLoadingNotification">
      <div class="pa-2">
        <v-skeleton-loader
          v-for="n in 11"
          :key="n"
          width="450"
          class="mt-2"
          style="border-radius: 10px"
          type="list-item-two-line"
        ></v-skeleton-loader>
      </div>
    </div>
    <v-list v-else subheader two-line :color="default_.color.main">
      <v-sheet
        v-if="notifications.length <= 0"
        height="400"
        class="d-flex justify-center align-center text-center"
      >
        <div>
          <EmptyComponent type="notifications" />
        </div>
      </v-sheet>

      <div v-else>
        <v-list-item
          @click="nextLink(item.ref, item)"
          v-for="item in notifications"
          :key="item._id"
          link
          class="square xyz-in"
          xyz="fade flip-up up-100% front"
        >
          <v-list-item-avatar size="32">
            <v-icon size="25">mdi-bell</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <div style="font-size: 16px; width: 200px">
              {{ item.name }}
            </div>

            <div style="opacity: 0.6; font-size: 13px; width: 50px">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> {{ item.describe }}</span>
                </template>
                <span>{{ item.describe }}</span>
              </v-tooltip>
            </div>
            <div style="opacity: 0.5; font-size: 12px" class="mt-1">
              <i>{{ formatDate(item.createdAt) }}</i>
            </div>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import EmptyComponent from "@/components/Empty.vue";

export default Vue.extend({
  props: [
    "default_",
    "notifications",
    "formatDate",
    "isLoadingNotification",
    "shortenText",
    "to",
  ],
  components: {
    EmptyComponent,
  },
  data: () => ({}),
  methods: {
    nextLink(ref: string, item: any) {
      if (
        item.name === "Bạn được người mua gọi" ||
        item.name === "Bạn được người bán gọi"
      ) {
        window.location.href = `/room?room=${ref.split("_")[2]}`;
      } else if (ref == "approved_room") {
        window.location.href = `/broker`;
      }
    },
  },
});
</script>

<style scoped>
.scroll-notification {
  overflow: scroll;
  height: 80vh;
}
</style>
