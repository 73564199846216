<template>
  <v-sheet
    height="300"
    :color="default_.color.main"
    class="d-flex justify-center align-center text-center"
  >
    <div>
      <div class="square xyz-in" xyz="fade down flip-up">
        <center>
          <div>
            <img
              alt="nftgame"
              loading="lazy"
              decoding="async"
              width="100"
              src="../../assets/check.png"
            />
          </div>
          <div style="font-size: 14px" class="mt-2">
            Còn
            <span class="font-weight-bold" style="color: yellow">{{
              time
            }}</span>
            giây trở về
          </div>
          <h2 class="mt-2">Giao dịch thành công</h2>
        </center>
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: [
    "default_",
    "coins",
    "loadingAnim",
    "main",
    "formatPrice",
    "symbolFrom",
    "symbolTo",
    "fee",
  ],
  data: () => ({
    time: 3,
    setInterval: null as any,
  }),
  created() {
    this.setInterval = setInterval(() => {
      if (this.time <= 1) {
        this.$emit("tabEmit", "home");
        clearInterval(this.setInterval);
      } else {
        this.time--;
      }
    }, 1000);
  },
});
</script>
