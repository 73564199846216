<template>
  <div>
    <div v-if="errorChooseToken">
      <div style="padding: 0px 0px 5px 0px" class="error--text">
        {{ errorChooseToken }}
      </div>
    </div>

    <div>
      <v-text-field
        prepend-inner-icon="fi-rr-search"
        hide-details=""
        placeholder="Tìm kiếm token"
        outlined
        rounded
        v-model="search"
      ></v-text-field>
    </div>

    <div class="mt-3 font-weight-bold" style="padding: 10px 0px">
      Chọn token
    </div>

    <div>
      <EmptyComponent v-if="filteredItems(coins).length <= 0" />
      <div class="list-scroll-token" v-else>
        <v-list subheader two-line :color="default_.color.main">
          <v-list-item
            v-show="item.symbol != 'NFTG Point'"
            @click="chooseToken(item)"
            v-for="item in filteredItems(coins)"
            :key="item._id"
            class="square xyz-in"
            xyz="fade flip-up up-100% front"
            link
          >
            <v-list-item-avatar size="32">
              <v-img :alt="item.name"
          loading="lazy"
          decoding="async" :src="item.image"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title style="font-size: 16px"
                >{{ item.symbol }}
                <v-btn
                  disabled
                  v-show="item.type == 'all'"
                  small
                  class="ml-2 text-none"
                >
                  Đa chuỗi</v-btn
                >
              </v-list-item-title>

              <v-list-item-subtitle style="width: 200px">{{ item.name }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <span :class="`square ${loadingAnim}`" xyz="fade down flip-up">
                  {{ formatCryptoNumber(item?.my) }}
                </span>
              </div>
              <v-list-item-subtitle style="font-size: 12px"
                >{{ formatCryptoNumber((item.price / main.price) * item.my) }}
                {{ main.symbol }}</v-list-item-subtitle
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import EmptyComponent from "@/components/Empty.vue";

export default Vue.extend({
  props: [
    "default_",
    "coins",
    "loadingAnim",
    "main",
    "formatPrice",
    "errorChooseToken",
    "formatCryptoNumber",
    "symbolFrom",
    "symbolTo",
    "type",
    "formatNumber"
  ],
  components: {
    EmptyComponent,
  },
  data: () => ({
    search: "",
    coins_swap: [] as any,
  }),
  async created() {
    if (this.type == "chooseCoin_swap_from") {
      this.coins_swap = this.coins.filter(
        (x: any) => x.symbol != this.symbolFrom.symbol
      );
    } else if (this.type == "chooseCoin_swap_to") {
      this.coins_swap = this.coins.filter(
        (x: any) => x.symbol != this.symbolTo.symbol
      );
    }
  },
  methods: {
    filteredItems(coins: any) {
      return coins.filter((item: any) => {
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.symbol.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    async chooseToken(item: any) {
      const time = Date.now();
      this.$emit("tokenEmit", {
        ...{ time },
        ...item,
      });
    },
  },
});
</script>

<style scoped>
.list-scroll-token {
  overflow: scroll;
  height: 50vh;
}
</style>
