<template>
  <span>
    <v-menu v-model="menu" :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span
          class="d-flex ml-2 float-end"
          v-if="user"
          style="font-size: 14px"
          v-bind="attrs"
          v-on="on"
        >
          <div class="">
            <v-avatar size="42">
              <v-img
                :src="user.picture"
                :alt="user.name"
                loading="lazy"
                decoding="async"
              ></v-img
            ></v-avatar>
          </div>
          <div class="ml-2" v-if="size.onsize > 960">
            <div>
              {{ user.name }}
              <v-icon size="12" style="margin-top: -4px"
                >fi fi-sr-caret-down</v-icon
              >
            </div>
            <div style="font-size: 12px">{{ user.email }}</div>
          </div>
        </span>
      </template>

      <v-card dark :loading="isLoadingMenu">
        <span>
          <v-list
            v-if="type == 3"
            dense
            :color="default_.color.main"
            style="border-radius: 10px"
          >
            <v-list-item
              @click="item.path == '/logOut' ? logOut() : toLink(item.path)"
              v-for="(item, i) in menu2"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon size="15">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </span>
      </v-card>
    </v-menu>

    <span v-if="type == 1">
      <v-btn
        v-show="size.onsize > 960"
        @click="toLink(item.path)"
        text
        large
        :disabled="!item.isActive"
        v-for="item in menus"
        class="text-none"
        :key="item.name"
        style="font-size: 14px"
      >
        <span :class="path == item.path ? 'text_th' : ''">
          {{ item.name }}</span
        >
      </v-btn>
    </span>

    <span v-else-if="type == 2">
      <v-btn
        @click="to(item.path)"
        text
        large
        block
        :disabled="!item.isActive"
        v-for="(item, i) in menus"
        class="text-none"
        :key="i"
      >
        <span :class="path == item.path ? 'text_th' : ''">
          {{ item.name }}</span
        >
      </v-btn>
    </span>
  </span>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: [
    "type",
    "size",
    "to",
    "path",
    "default_",
    "logOut",
    "user",
    "isLoadingMenu",
  ],
  data: () => ({
    menu: false,
    menus: [
      {
        name: "Nhà môi giới",
        path: "/broker/list",
        isActive: true,
        icon: "fi fi-sr-user-crown",
      },
      {
        name: "P2P",
        path: "/marketplace",
        isActive: true,
        icon: "fi fi-sr-users",
      },
      {
        name: "Airdrop",
        path: "/airdrop",
        isActive: true,
        icon: "fi fi-rr-gift",
      },
      {
        name: "Game",
        path: "/game",
        isActive: true,
        icon: "fi fi-rr-puzzle",
      },
      {
        name: "Sự kiện",
        path: "/event",
        isActive: true,
        icon: "fi fi-rr-star",
      },
    ],
    menu2: [
      {
        name: "Bot Telegram",
        icon: "fi fi-brands-telegram",
        path: "/bot-telegram",
      },
      {
        name: "NFT của tôi",
        icon: "fi fi-rr-palette",
        path: "/account/nfts/Ox?chain=ethereum",
      },
      {
        name: "Phương thức thanh toán",
        icon: "fi fi-rr-credit-card",
        path: "/payment",
      },
      {
        name: "Đăng ký làm nhà môi giới",
        icon: "fi fi-rr-user-crown",
        path: "/broker",
      },
      {
        name: "Lịch sử giao dịch",
        icon: "fi fi-rr-time-past",
        path: "/room/history",
      },
      {
        name: "Đăng xuất",
        icon: "fi fi-rr-exit",
        path: "/logOut",
      },
    ],
  }),
  methods: {
    toLink(path: any) {
      this.menu = false;
      this.to(path);
    },
  },
  created() {
    this.$emit("menusEmit", this.menus);
  },
});
</script>
