<template>
  <div>
    <div v-if="errorChooseChain">
      <div style="padding: 0px 0px 5px 0px" class="error--text">
        {{ errorChooseChain }}
      </div>
    </div>
    <div>
      <v-text-field
        prepend-inner-icon="fi-rr-search"
        hide-details=""
        placeholder="Tìm kiếm mạng"
        outlined
        rounded
        v-model="search"
      ></v-text-field>
    </div>
    <div class="font-weight-bold" style="padding: 10px 0px">Chọn mạng</div>
    <div>
      <v-list subheader two-line :color="default_.color.main">
        <EmptyComponent type="chain" v-if="filteredItems(chains).length <= 0" />

        <div class="list-scroll-chain" v-else>
          <v-list-item
            @click="chooseChain(item)"
            v-for="item in filteredItems(chains)"
            :key="item._id"
            class="square xyz-in"
            xyz="fade flip-up up-100% front"
            link
          >
            <v-badge avatar bordered bottom offset-x="10" offset-y="15">
              <template v-slot:badge>
                <v-avatar>
                  <v-img
                    :alt="token.name"
                    loading="lazy"
                    decoding="async"
                    :src="token.image"
                  ></v-img>
                </v-avatar>
              </template>
              <v-avatar size="32">
                <v-img
                  :alt="item.chain_coin.name"
                  loading="lazy"
                  decoding="async"
                  :src="item.chain_coin.image"
                ></v-img>
              </v-avatar>
            </v-badge>

            <v-list-item-content class="ml-4">
              <v-list-item-title style="font-size: 16px"
                >{{
                  item.chain_coin.name == "Solona"
                    ? "Solana"
                    : item.chain_coin.name
                }}
              </v-list-item-title>

              <v-list-item-subtitle style="width: 220px">
                {{
                  item.chain_coin.name == "Solona"
                    ? "Solana"
                    : item.chain_coin.name
                }}

                <span v-if="type == 'chooseChain_send'">
                  |Phí rút:
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.chain_coin.feeWithdraw)
                  }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action style="font-size: 14px">
              <div>{{ item.chain_coin.standard }}</div>
              <v-list-item-subtitle>{{ token.symbol }}</v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import EmptyComponent from "@/components/Empty.vue";

export default Vue.extend({
  props: [
    "default_",
    "coins",
    "loadingAnim",
    "main",
    "formatPrice",
    "chains",
    "errorChooseChain",
    "token",
    "type",
  ],
  components: {
    EmptyComponent,
  },
  data: () => ({
    search: "",
  }),
  async created() {},
  methods: {
    filteredItems(items: any) {
      return items.filter((item: any) => {
        return item.chain_coin.name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    async chooseChain(item: any) {
      const time = Date.now();
      this.$emit("chooseChainEmit", {
        ...{ time },
        ...item,
      });
    },
  },
});
</script>

<style scoped>
.list-scroll-chain {
  overflow: scroll;
  height: 50vh;
}
</style>
