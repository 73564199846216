<template>
  <div>
    <div>
      <div style="padding: 5px; margin-top: -15px">
        <div style="font-size: 32px" class="ml-2">
          <div class="d-flex">
            <div>
              <v-avatar size="30">
                <v-img
                  :alt="main.symbol"
                  loading="lazy"
                  decoding="async"
                  :src="main.image"
                ></v-img>
              </v-avatar>
            </div>
            <div class="ml-2" style="margin-top: 1px">
              <span :class="`square ${loadingAnim}`" xyz="fade down flip-up">
                {{ formatCryptoNumber(main.my).split(",")[0] }}
              </span>
              <!-- {{ main.symbol }} -->
            </div>
          </div>
        </div>
      </div>
      <v-row class="text-center">
        <v-col cols="3">
          <v-btn fab depressed @click="clickChangeClick('send')">
            <v-icon>fi fi-rr-arrow-small-up</v-icon>
          </v-btn>
          <div class="mt-2">Rút</div>
        </v-col>

        <v-col cols="3" @click="clickChangeClick('chooseCoin_deposit')">
          <v-btn fab depressed>
            <v-icon>fi fi-rr-arrow-small-down</v-icon>
          </v-btn>
          <div class="mt-2">Nạp</div>
        </v-col>

        <v-col cols="3">
          <v-btn fab depressed @click="clickChangeClick('swap')">
            <v-icon>fi-rr-convert-shapes</v-icon>
          </v-btn>
          <div class="mt-2">Hoán đổi</div>
        </v-col>

        <v-col cols="3">
          <v-btn fab depressed @click="clickChangeClick('history')">
            <v-icon>fi fi-rr-time-past</v-icon>
          </v-btn>
          <div class="mt-2">Lịch sử</div>
        </v-col>
      </v-row>
    </div>

    <v-divider class="mt-5"></v-divider>

    <div>
      <div style="padding: 10px 0px">
        <v-row>
          <v-col cols="6">
            <div style="font-size: 18px; padding: 10px 10px">Tiền mã hóa</div>
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              append-icon="mdi-magnify"
              dense
              hide-details=""
              rounded
              class="mt-1"
              placeholder="Tìm kiếm"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div class="list-scroll">
        <EmptyComponent v-if="filteredItems(coins).length <= 0" />

        <v-list rounded subheader two-line :color="default_.color.main" v-else>
          <v-list-item
            v-for="item in filteredItems(coins)"
            :key="item._id"
            link
            style="border-radius: 10px"
            :class="`square xyz-in ${item.symbol == 'NFTG Point' ? 'bbr' : ''}`"
            xyz="fade flip-up up-100% front"
            @click="clickChangeToken('detailsToken', item)"
          >
            <v-list-item-avatar size="32">
              <v-img
                :alt="item.symbol"
                loading="lazy"
                decoding="async"
                :src="item.image"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title style="font-size: 16px">
                {{ item.symbol }}
              </v-list-item-title>

              <v-list-item-subtitle>
                <span v-if="item.symbol !== 'NFTG Point'"><span style="font-size: 12px">$</span><span style="margin-left: 1px">{{
                  formatCryptoNumber(item.price)
                }}</span></span>
                <span v-else class="text-yellow font-weight-bold"
                  >Đang khai thác</span
                >
                <div
                  style="opacity: 0.5; font-size: 12px"
                  v-if="item.symbol == 'NFTG Point'"
                >
                  Bấm vào để khai khác {{ item.symbol }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <span :class="`square ${loadingAnim}`" xyz="fade down flip-up">
                  {{ formatCryptoNumber(item?.my) }}
                </span>
              </div>
              <v-list-item-subtitle style="font-size: 12px">{{
                formatCryptoNumber((item.price / main.price) * item.my) +
                " " +
                main.symbol
              }}</v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import EmptyComponent from "@/components/Empty.vue";

export default Vue.extend({
  props: [
    "isLoadingCoin",
    "coins",
    "isLoading",
    "loadingAnim",
    "main",
    "default_",
    "user",
    "formatPrice",
    "formatCryptoNumber",
    "formatDate",
    "formatDollar",
    "formatNumber",
  ],
  components: {
    EmptyComponent,
  },
  data: () => ({
    search: "",
  }),
  methods: {
    filteredItems(coins: any) {
      return coins.filter((item: any) => {
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.symbol.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    clickChangeClick(tab: string) {
      this.$emit("tabEmit", tab);
    },
    clickChangeToken(tab: string, item: any) {
      if (item.symbol == "NFTG Point") {
        window.location.href = String(process.env.VUE_APP_API_URL_GAME);
      } else {
        this.$emit("tabEmit", tab);
        this.$emit("coinEmit", item);
      }
    },
  },
});
</script>

<style>
.list-scroll {
  overflow: scroll;
  height: 40vh;
}
.bbr {
  box-shadow: rgba(100, 100, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
</style>
