<template>
  <v-app onbeforeunload="">
    <HeaderComponent
      @mainCoinEmit="mainCoin = $event"
      @coinsEmit="coins = $event"
      @feeEmit="fee = $event"
      :user="user"
      :default_="default_"
      :size="size"
      :formatCryptoNumber="formatCryptoNumber"
      :to="to"
      :loadWallet_="loadWallet_"
      :formatDate="formatDate"
      :formatNumber="formatNumber"
      :balance="balance"
      :notifications="notifications"
      :isCheckNofition="isCheckNofition"
      @isCheckNofitionEmit="isCheckNofition_ = $event"
      :socket="socket"
      :BSON="BSON"
      :path="path"
      :formatDollar="formatDollar"
      :timelineWallet="timelineWallet"
      :subString="subString"
      :shortenText="shortenText"
      :error="error"
      type_code="home"
    />

    <v-main>
      <v-sheet dark :color="default_.color.main">
        <router-view
          :formatDate="formatDate"
          :formatCryptoNumber="formatCryptoNumber"
          :mainCoin="mainCoin"
          :default_="default_"
          :user="user"
          :size="size"
          :socket="socket"
          :path="path"
          :to="to"
          :coins="coins"
          @loadWalletEmit="loadWallet_ = $event"
          :toLink="toLink"
          @balanceEmit="balance = $event"
          :formatNumber="formatNumber"
          :fee="fee"
          :error="error"
          :success="success"
          :formatDollar="formatDollar"
          :shortenText="shortenText"
        />
      </v-sheet>

      <v-dialog
        v-model="dialogSession"
        max-width="400"
        persistent
      >
        <v-card :color="default_.color.main" dark>
          <v-card-title class="text-h5"> Thông báo Testnet </v-card-title>

          <v-card-text style="font-size: 18px">
            Bạn đang truy cập trang testnet của NFTG exchange, tất cả tính năng chỉ phục vụ cho việc test và không có giá trị. Vui lòng truy cập
            <v-btn class="bg_main_main_blue" href="https://nftgexchange.com">NFTG Exchange Mainnet</v-btn> để tham gia giao dịch. Đường link chính thức <a href="https://nftgexchange.com/" class="text_th">https://nftgexchange.com</a>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn @click="dialogSession = false" color="green darken-1" class="text-none" text>
              Tiếp tục
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>

    <FooterComponent :to="to" :default_="default_" />

    <v-dialog v-model="dialogBan" max-width="400" persistent>
      <v-card :color="default_.color.main" dark>
        <v-card-title class="text-h5"> Cảnh báo </v-card-title>

        <v-card-text style="font-size: 18px">
          Tài khoản của bạn phát hiện nghi vấn. Mọi hoạt động liên quan đến tài
          khoản này đều bị chúng tôi xem xét để ra quyết định tài khoản có thể
          hoạt động tiếp hay không. Vui lòng liên hệ admin để giải quyết.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="dialogBan = false"
            color="green darken-1"
            class="text-none"
            text
          >
            Tiếp tục
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import HeaderComponent from "@/components/Header.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  props: [
    "default_",
    "user",
    "size",
    "socket",
    "notifications",
    "notificationCancelBroker",
    "formatNumber",
    "formatDate",
    "formatCryptoNumber",
    "isCheckNofition",
    "error",
    "success",
    "BSON",
    "path",
    "formatDollar",
    "to",
    "timelineWallet",
    "subString",
    "shortenText",
  ],
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data: () => ({
    dialogBan: false,
    dialogSession: false,
    mainCoin: {},
    coins: [],
    loadWallet_: "",
    balance: null,
    fee: {},
    isCheckNofition_: null,
  }),
  async created() {
    if (this.user?._id) {
      if (this.user.band) {
        this.dialogBan = true;
      }
    }
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleUnload);
  },

  methods: {
    async toLink(path, link) {
      if (this.user?._id) {
        if (this.user.band) {
          this.dialogBan = true;
        }
      }
      this.$router.push({
        path: "/login?link=" + link,
      });
    },
    // async to(path) {
    //   try {
    //     if (this.user?._id) {
    //       if (this.user.band) {
    //         this.dialogBan = true;
    //       }
    //     }
    //     this.dialogCancel = false;
    //     if (this.$route.path != path) {
    //       window.scrollTo(0, 0);
    //       this.$router.push({
    //         path: path,
    //       });
    //     }
    //   } catch (error) {
    //     if (this.$route.path !== "/error") {
    //       this.$router.push({
    //         path: "/error",
    //       });
    //     }
    //   }
    // },
  },
  watch: {
    isCheckNofition_() {
      this.$emit("isCheckNofitionEmit", this.isCheckNofition_);
    },
    $route() {
      // this.disconnect(this.socket);
    },
  },
};
</script>

<style>
.v-application--wrap {
  min-height: 0% !important;
}

.container-main {
  max-width: 1400px !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90%;
  border-radius: 20px;
}
</style>
