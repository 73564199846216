import axios from 'axios'
import { checkError } from "../api/error"

class WalletClass {
    private url = process.env.VUE_APP_BACKEND_URL + '/api/v1/wallet';

    public async getCoins() {
        try {
            const response = await axios.get(this.url + '/coins');
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async getWallet() {
        try {
            const response = await axios.get(this.url + '/get-wallet', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getFee() {
        try {
            const response = await axios.get(this.url + '/fee', {
                headers: {
                    happy: window.location.hostname
                }
            });
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async swap(symbolFrom: string, symbolTo: string, amountFrom: number) {
        try {
            const response = await axios.post(this.url + '/swap', { symbolFrom, symbolTo, amountFrom }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async withdraw(symbolId: string, chainId: string, amount: number, address: string) {
        try {
            const response = await axios.post(this.url + '/withdraw', { symbolId, chainId, amount, address }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async transfers(symbolId: string, amount: number, email: string) {
        try {
            const response = await axios.post(this.url + '/transfers', { symbolId, amount, email }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async deposit(symbol: string, chainId: string) {
        try {
            const response = await axios.get(this.url + '/deposit', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                params: {
                    symbol,
                    chainId
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async transactions(symbol: string) {
        try {
            const response = await axios.get(this.url + '/transactions', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                params: {
                    symbol
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getBank() {
        try {
            const response = await axios.get(this.url + '/get-bank', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getChains(symbol: string) {
        try {
            const response = await axios.get(this.url + '/chains', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                params: {
                    symbol
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }
}

export default new WalletClass()