import axios from 'axios'
class CollectionsClass {
    private url = process.env.VUE_APP_BACKEND_URL + '/api/v1/collections';

    public async getCollections(type: string) {
        try {
            const response = await axios.get(this.url + '/list', {
                params: {
                    type
                }
            });
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async getSliders() {
        try {
            const response = await axios.get(this.url + '/sliders');
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async getNfts(_id: string) {
        try {
            const response = await axios.get(this.url + '/nfts', {
                params: {
                    colectionId: _id
                }
            });
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async viewCollections(_id: string, type?: number) {
        try {
            const response = await axios.get(this.url + '/view', {
                params: {
                    _id,
                    type
                }
            });

            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async getPost(ref: string) {
        try {
            const response = await axios.get(this.url + '/get-post', {
                params: {
                    ref
                }
            });

            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async getPostById(postId: string) {
        try {
            const response = await axios.get(this.url + '/get-post-byid', {
                params: {
                    postId
                }
            });

            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }
}

export default new CollectionsClass()