<template>
  <div>
    <notifications
      v-if="type == 'error'"
      group="error"
      position="top center"
      style="margin-top: 20px; padding: 10px; color: white"
    >
      <template slot="body" slot-scope="props">
        <div class="mt-2">
          <div
            class="bg_main_main_red"
            style="border: 0.2px solid #f5f5f5; border-radius: 10px"
          >
            <div style="padding: 20px 20px">
              <center>
                <h3>{{ props.item.title }}</h3>
              </center>
            </div>
          </div>
        </div>
      </template>
    </notifications>

    <notifications
      v-else
      group="success"
      position="top center"
      style="margin-top: 20px; padding: 10px; color: white"
    >
      <template slot="body" slot-scope="props">
        <div class="mt-2">
          <div
            class="bg_main_main_green"
            style="border: 0.2px solid #f5f5f5; border-radius: 10px"
          >
            <div style="padding: 20px 20px">
              <center>
                <h3>{{ props.item.title }}</h3>
              </center>
            </div>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["type"],
  data: () => ({}),
});
</script>
