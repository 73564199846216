<template>
  <div class="mt-2" >
    <div v-if="transactions.length <= 0">
      <EmptyComponent type="transactions" class="mt-8" />
    </div>
    <div class="s-t mt-2" v-else>
      <div style="padding: 0px 5px">
        <v-list subheader two-line :color="default_.color.main">
          <v-list-item
            v-for="(item, i) in transactions"
            :key="i"
            link
            class="square xyz-in w-100"
            xyz="fade flip-up up-100% front"
            @click="clickChangeToken('detailsToken', item)"
          >
            <v-list-item-avatar size="36">
              <v-img
                :alt="item.desc"
                loading="lazy"
                decoding="async"
                :src="coin.image"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title style="font-size: 13px">
                {{ formatDate(item.createdAt) }}
              </v-list-item-title>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    style="font-size: 11px; opacity: 0.6"
                  >
                    {{ !item.ref.includes('fee_withdraw') ? item.desc : item.ref.split(",")[1] }}
                  </div>
                </template>
                <span>{{ item.desc }}</span>
              </v-tooltip>
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <div class="font-weight-bold mt-2" style="font-size: 13px">
                  <div
                    v-if="item.action == 'receive' || item.action == 'deposit'"
                    class="text-green"
                  >
                    + {{item.amount < 1 ? item.amount : formatCryptoNumber(item.amount) }} {{ item.symbol }}
                  </div>
                  <div v-else class="text-red">
                    - {{item.amount < 1 ? item.amount : formatCryptoNumber(item.amount) }} {{ item.symbol }}
                  </div>
                </div>
              </div>
              <v-list-item-subtitle style="font-size: 12px">
                <div class="mt-1">
                  <div
                    v-if="item.status == 'pending'"
                    class="yellow--text text--lighten-1"
                  >
                    Đang duyệt
                  </div>
                  <div
                    v-else-if="item.status == 'rejected'"
                    class="red--text text--lighten-1"
                  >
                    Từ chối
                  </div>
                  <div v-else class="text-green">Hoàn thành</div>
                </div>
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import EmptyComponent from "@/components/Empty.vue";

export default Vue.extend({
  props: [
    "transactions",
    "formatCryptoNumber",
    "formatDate",
    "coin",
    "default_",
    "shortenText",
  ],
  components: {
    EmptyComponent,
  },
  data: () => ({}),
});
</script>

<style scoped>
.s-t {
  overflow: scroll;
  height: 500px;
}
</style>
