<template>
  <v-sheet style="border-radius: 10px" class="total mt-2">
    <div class="pa-4">
      <v-row no-gutters>
        <v-col cols="6"> Tài khoản </v-col>

        <v-col cols="6" class="text-end">
          <span> {{ user.name }}</span>
        </v-col>
        <v-col cols="6"> Số dư </v-col>

        <v-col cols="6" class="text-end">
          <span
            class="font-weight-bold"
            :style="`font-size: ${size ? size : 18}px`"
          >
            {{ formatCryptoNumber(total) }}</span
          >
          <span class="ml-2" style="opacity: 0.7">{{
            symbol ? symbol : mainCoin.symbol
          }}</span>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["mainCoin", "total", "formatCryptoNumber", "user", "size", "symbol"],
  data: () => ({}),
});
</script>

<style scoped>
.total {
  background: linear-gradient(to right, #054e4e, #3c6381);
}
</style>
