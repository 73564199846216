<template>
  <span>
    <v-menu
      v-if="user"
      v-model="menu"
      transition="slide-y-transition"
      :close-on-content-click="false"
      :nudge-width="350"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="clickDialogWallet()"
          large
          rounded
          depressed
          class="text-none bg_main_main_blue"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>fi-rr-wallet</v-icon>
          <span class="ml-1">Wallet</span>
        </v-btn>
      </template>

      <v-card style="border-radius: 20px" :color="default_.color.main" dark>
        <v-card
          v-if="isLoading"
          width="450"
          height="500"
          class="d-flex justify-center align-center text-center"
        >
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </v-card>
        <div v-else>
          <div>
            <div v-if="tab == 'home'">
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <img
                      loading="lazy"
                      decoding="async"
                      :src="user.picture"
                      :alt="user.email"
                    />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      user.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action> </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>

            <div v-else>
              <v-toolbar
                flat
                dark
                v-if="tab != 'load'"
                style="border-radius: 10px 10px 0px 0px"
              >
                <v-btn icon dark @click="back()">
                  <v-icon>fi-rr-angle-small-left</v-icon>
                </v-btn>
                <v-toolbar-title v-if="type == 'detailsToken'">
                  <div class="d-flex">
                    <div>
                      <v-avatar size="35">
                        <v-img
                          :alt="symbolFrom.name"
                          loading="lazy"
                          decoding="async"
                          :src="symbolFrom.image"
                        ></v-img>
                      </v-avatar>
                    </div>
                    <div class="ml-2 font-weight-bold">
                      <div style="font-size: 14px">{{ symbolFrom.symbol }}</div>
                      <div style="font-size: 12px; opacity: 0.5">
                        {{ symbolFrom.name }}
                      </div>
                    </div>
                  </div>
                </v-toolbar-title>
                <v-toolbar-title v-else>
                  {{ title(tab) }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-toolbar-items>
                  <v-btn dark text @click="dialog = false"> Save </v-btn>
                </v-toolbar-items>
                <v-btn dark icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn> -->
              </v-toolbar>
            </div>
          </div>

          <div class="pa-4">
            <div v-if="tab == 'home'">
              <ManageComponent
                :formatCryptoNumber="formatCryptoNumber"
                @chooseTokenEmit="chooseToken = $event"
                :user="user"
                :isLoadingCoin="isLoadingCoin"
                :coins="coins"
                :isLoading="isLoading"
                :loadingAnim="loadingAnim"
                :main="main"
                :default_="default_"
                :formatPrice="formatPrice"
                @tabEmit="tab = $event"
                @coinEmit="symbolFrom = $event"
                :formatDate="formatDate"
                :formatDollar="formatDollar"
                :formatNumber="formatNumber"
              />
            </div>

            <div v-else-if="tab == 'load'">
              <v-sheet
                height="300"
                width="450"
                :color="default_.color.main"
                class="d-flex justify-center align-center text-center"
              >
                <div>
                  <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>

                  <div style="font-size: 16px" class="mt-2">
                    Chờ xíu nhé, đang tải dữ liệu
                  </div>
                </div>
              </v-sheet>
            </div>

            <div v-else-if="tab == 'swap'">
              <SwapComponent
                :formatCryptoNumber="formatCryptoNumber"
                @chooseTokenEmit="chooseToken = $event"
                :loadingAnim="loadingAnim"
                :coins="coins"
                :default_="default_"
                :main="main"
                :formatPrice="formatPrice"
                :symbolFrom="symbolFrom"
                :symbolTo="symbolTo"
                @symbolFromEmit="symbolFrom = $event"
                @symbolToEmit="symbolTo = $event"
                :fee="fee"
                @tabEmit="tab = $event"
                @swapChangeEmit="swapData = $event"
                :formatNumber="formatNumber"
              />
            </div>

            <div v-else-if="tab == 'notification'">
              <notification-component
                :loadingAnim="loadingAnim"
                :coins="coins"
                :default_="default_"
                :main="main"
                :formatPrice="formatPrice"
                @tokenEmit="token = $event"
                @tabEmit="tab = $event"
              />
            </div>

            <div v-else-if="tab == 'choose_token'">
              <ChooseTokenComponent
                :formatCryptoNumber="formatCryptoNumber"
                :loadingAnim="loadingAnim"
                :coins="coins"
                :default_="default_"
                :main="main"
                :formatPrice="formatPrice"
                @tokenEmit="token = $event"
                @tabEmit="tab = $event"
                :errorChooseToken="errorChooseToken"
                :formatNumber="formatNumber"
              ></ChooseTokenComponent>
            </div>

            <div v-else-if="tab == 'choose_chain'">
              <ChooseChainComponent
                :token="token"
                :loadingAnim="loadingAnim"
                :coins="coins"
                :default_="default_"
                :main="main"
                :symbolFrom="symbolFrom"
                :formatPrice="formatPrice"
                @tokenEmit="token = $event"
                @tabEmit="tab = $event"
                :chains="chains"
                @chooseChainEmit="chain = $event"
                :errorChooseChain="errorChooseChain"
                :type="type"
              ></ChooseChainComponent>
            </div>

            <div v-else-if="tab == 'deposit'">
              <deposit-component
                :loadingAnim="loadingAnim"
                :coins="coins"
                :default_="default_"
                :main="main"
                :symbolFrom="symbolFrom"
                :formatPrice="formatPrice"
                @tokenEmit="token = $event"
                @tabEmit="tab = $event"
                :deposit="deposit"
                :chain="chain"
                :user="user"
                :formatCryptoNumber="formatCryptoNumber"
              ></deposit-component>
            </div>

            <div v-else-if="tab == 'send'">
              <SendComponent
                :formatCryptoNumber="formatCryptoNumber"
                :tabSend="tabSend"
                @tabSendEmit="tabSend = $event"
                :loadingAnim="loadingAnim"
                :coins="coins"
                :default_="default_"
                :fee="fee"
                :main="main"
                :symbolFrom="symbolFrom"
                :formatPrice="formatPrice"
                @tokenEmit="token = $event"
                @tabEmit="tab = $event"
                :deposit="deposit"
                :chain="chain"
                :isLoadingSend="isLoadingSend"
                :chains="chains"
                @chooseTokenEmit="coinSend = $event"
                @chooseChainEmit="chainSendMain = $event"
                :chainSendMain="chainSendMain"
                :isChain="isChain"
                @withdrawChangeEmit="withdrawData = $event"
                @transfersChangeEmit="transfersData = $event"
                :formatNumber="formatNumber"
              ></SendComponent>
            </div>

            <div v-else-if="tab == 'detailsToken'">
              <DetailsToken
                :formatDate="formatDate"
                :transactions="transactions"
                :formatCryptoNumber="formatCryptoNumber"
                :main="main"
                :symbolFrom="symbolFrom"
                @tabEmit="tab = $event"
                :isLoadingTransaction="isLoadingTransaction"
                :default_="default_"
                @coinDepositEmit="coinDeposit = $event"
                :shortenText="shortenText"
                :size="size"
              ></DetailsToken>
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
  </span>
</template>

<script lang="ts">
import WalletApi from "@/api/wallet";
import ManageComponent from "@/components/wallet/Manage.vue";
import SwapComponent from "@/components/wallet/SwapWallet.vue";
import ChooseTokenComponent from "@/components/wallet/ChooseToken.vue";
import NotificationComponent from "@/components/wallet/Notification.vue";
import DepositComponent from "@/components/wallet/Deposit.vue";
import ChooseChainComponent from "@/components/wallet/ChooseChain.vue";
import SendComponent from "@/components/wallet/SendWallet.vue";
import DetailsToken from "@/components/wallet/DetailsToken.vue";

import Vue from "vue";
import AuthApi from "@/api/auth";

export default Vue.extend({
  props: [
    "default_",
    "user",
    "formatCryptoNumber",
    "loadWallet_",
    "formatDate",
    "formatNumber",
    "balance",
    "socket",
    "BSON",
    "formatDollar",
    "timelineWallet",
    "isCheckNofition",
    "notificationRef",
    "size",
    "shortenHash",
    "shortenText",
  ],
  components: {
    ManageComponent,
    SwapComponent,
    ChooseTokenComponent,
    NotificationComponent,
    DepositComponent,
    ChooseChainComponent,
    SendComponent,
    DetailsToken,
  },
  data: () => ({
    coinDeposit: {} as any,
    tab: "home",
    menu: false,
    isLoadingCoin: true,
    coins: [] as any,
    main: {} as any,
    isLoading: true,
    loadingAnim: "xyz-in",
    chooseToken: "",
    symbolFrom: {} as any,
    symbolTo: {} as any,
    token: {} as any,
    fee: {},
    swapData: {} as any,
    withdrawData: {} as any,
    transfersData: {} as any,
    chains: [] as any,
    deposit: {} as any,
    chain: {} as any,
    choose_chain: {} as any,
    errorChooseChain: "" as string,
    errorChooseToken: "" as string,
    isLoadingSend: false,
    chainSend: {} as any,
    coinSend: {} as any,
    chainSendMain: {} as any,
    isChain: false,
    type: "" as any,
    tabSend: 0,
    transactions: [] as any,
    isLoadingTransaction: false,
    priceCoins: [] as any,
  }),
  async created() {
    await this.disconnetCoin();
    await this.loadCoins();

    if (this.user?._id) {
      this.loadFee();
      await this.loadWallet();
    }
  },
  watch: {
    isCheckNofition() {
      if (
        this.notificationRef == "deposit" ||
        this.notificationRef == "deposit_block"
      ) {
        this.loadWallet();
      }
    },
    timelineWallet() {
      this.loadWallet();
    },
    async menu() {
      if (!this.menu) {
        await this.disconnetCoin();
        setTimeout(() => {
          if (this.tab == "send") [(this.tab = "home")];
        }, 500);
      }
    },
    async balance() {
      this.loadWallet();
    },
    async coinSend() {
      this.tab = "choose_token";
      if (this.chains.length > 1) {
        this.isChain = true;
      }
    },
    async chainSendMain() {
      if (this.chains.length > 1) {
        this.tab = "choose_chain";
      }
    },
    async tab() {
      if (this.tab == "chooseCoin_deposit") {
        this.type = "chooseCoin_deposit";
        this.tab = "choose_token";
      } else if (this.tab == "swap_from") {
        this.type = "chooseCoin_swap_from";
        this.tab = "choose_token";
        this.coins = this.coins.filter((x: any) => x.isSwap);
      } else if (this.tab == "swap_to") {
        this.type = "chooseCoin_swap_to";
        this.tab = "choose_token";
        this.coins = this.coins.filter((x: any) => x.isSwap);
      } else if (this.tab == "swap") {
        this.type = "swap";
        const coins = this.coins.filter((x: any) => x.isSwap);

        if (!this.token.symbol) {
          const random = Math.floor(
            Math.random() *
              coins.filter((x: any) => x.symbol != this.main.symbol).length
          );
          this.symbolFrom = coins.filter(
            (x: any) => x.symbol != this.main.symbol
          )[random];
          this.symbolTo = this.main;
        }
      } else if (this.tab == "deposit") {
        this.type = "deposit";
      } else if (this.tab == "chooseToken_send") {
        this.type = "chooseToken_send";
        this.tab = "choose_token";
      } else if (this.tab == "send") {
        this.type = "send";
        this.isLoadingSend = true;
        if (this?.coinDeposit?.symbol) {
          this.symbolFrom = this?.coinDeposit;

          const choooseChain = await WalletApi.getChains(
            this?.coinDeposit?.symbol
          );

          if (choooseChain.success) {
            if (choooseChain.data.length > 1) {
              this.isChain = false;
            } else {
              this.isChain = true;
            }

            this.chains = choooseChain.data;
            this.chain = choooseChain.data[0];
            this.coinDeposit = {};
          }
        } else {
          this.symbolFrom = this.symbolFrom?._id
            ? this.symbolFrom.symbol == "NFTG Point"
              ? this.coins[2]
              : this.symbolFrom
            : this.coins[2];
          const choooseChain = await WalletApi.getChains(
            this.symbolFrom.symbol
          );

          if (choooseChain.success) {
            if (choooseChain.data.length > 1) {
              this.isChain = false;
            } else {
              this.isChain = true;
            }

            this.chains = choooseChain.data;
            this.chain = this.chain?._id ? this.chain : choooseChain.data[0];
          }
        }

        this.isLoadingSend = false;
      } else if (this.tab == "chooseChain_send") {
        this.type = "chooseChain_send";
        this.tab = "choose_chain";
      } else if (this.tab == "detailsToken") {
        this.type = "detailsToken";
        this.isLoadingTransaction = true;

        const transansactions = await WalletApi.transactions(
          this.symbolFrom.symbol
        );

        if (transansactions.success) {
          this.transactions = transansactions.data;
        } else {
          this.transactions = [];
        }

        this.isLoadingTransaction = false;
      } else if (this.tab == "send_details") {
        this.tab = "send";
        this.type = "send";
      } else if (this.tab == "chooseCoin_deposi_details") {
        this.tab = "load";
        this.symbolFrom = this.coinDeposit;
        const choooseChain = await WalletApi.getChains(this.coinDeposit.symbol);
        if (choooseChain.success) {
          if (choooseChain.data.length > 1) {
            this.symbolFrom = this.coinDeposit;
            this.chains = choooseChain.data;
            this.tab = "choose_chain";
            this.type = "chain_deposit";
          } else {
            this.tab = "deposit";
            this.type = "deposit";
            if (choooseChain.data.length == 0) {
              this.errorChooseToken =
                this.token.symbol + " tạm thời không thể giao dịch.";
              this.tab = "choose_token";
              this.type = "chooseCoin_deposit";
            } else {
              this.chain = choooseChain.data[0];
              const deposit = await WalletApi.deposit(
                this.coinDeposit.symbol,
                choooseChain.data[0].chain_coin._id
              );
              if (deposit.success) {
                this.deposit = deposit.data;
                this.tab = "deposit";
                this.type = "deposit";
              }
            }
          }
        }
      } else if (this.tab == "history") {
        this.type = "chooseCoin_history";
        this.tab = "choose_token";
      }

      this.isLoadingSend = false;
    },
    async chain() {
      if (this.type == "chain_deposit") {
        this.tab = "load";
        const deposit = await WalletApi.deposit(
          this.coinDeposit?.symbol
            ? this.coinDeposit?.symbol
            : this.token.symbol,
          this.chain.chain_coin._id
        );
        if (deposit.success) {
          this.deposit = deposit.data;
          this.tab = "deposit";
        } else {
          this.tab = "choose_chain";
          this.errorChooseChain = deposit.message;
        }
      } else if (this.type == "chooseChain_send") {
        this.tab = "send";
      }
    },
    async swapData() {
      this.loadWallet();
    },
    async withdrawData() {
      this.loadWallet();
    },
    async transfersData() {
      this.loadWallet();
    },
    async token() {
      this.tab = "load";
      if (this.type == "chooseCoin_deposit") {
        this.symbolFrom = this.token;
        const choooseChain = await WalletApi.getChains(this.token.symbol);
        if (choooseChain.success) {
          if (choooseChain.data.length > 1) {
            this.chains = choooseChain.data;
            this.tab = "choose_chain";
            this.type = "chain_deposit";
          } else {
            this.tab = "deposit";
            this.type = "deposit";
            if (choooseChain.data.length == 0) {
              this.errorChooseToken =
                this.token.symbol + " tạm thời không thể giao dịch.";
              this.tab = "choose_token";
              this.type = "chooseCoin_deposit";
            } else {
              this.chain = choooseChain.data[0];
              const deposit = await WalletApi.deposit(
                this.token.symbol,
                choooseChain.data[0].chain_coin._id
              );
              if (deposit.success) {
                this.deposit = deposit.data;
                this.tab = "deposit";
                this.type = "deposit";
              }
            }
          }
        }
      } else if (this.type == "chooseCoin_swap_from") {
        this.tab = "swap";
        this.symbolFrom = this.token;
        this.type = "swap";
      } else if (this.type == "chooseCoin_swap_to") {
        this.tab = "swap";
        this.symbolTo = this.token;
        this.type = "swap";
      } else if (this.type == "chooseToken_send") {
        this.tab = "send";
        this.type = "send";
        this.symbolFrom = this.token;

        this.isLoadingSend = true;
        this.symbolFrom = this.token;
        const choooseChain = await WalletApi.getChains(this.token.symbol);
        if (choooseChain.success) {
          if (choooseChain.data.length > 1) {
            this.isChain = false;
          } else {
            this.isChain = true;
          }

          this.chains = choooseChain.data;
          this.chain = choooseChain.data[0];
        }

        this.isLoadingSend = false;
      } else if (this.type == "chooseCoin_history") {
        this.tab = "detailsToken";
        this.type = "detailsToken";
        this.symbolFrom = this.token;
      }

      this.isLoadingSend = false;
    },
    loadWallet_() {
      this.loadWallet();
    },
  },
  async mounted() {
    // this.socket.on("price",async (data: any) => {
    //   try {
    //     const data_ = this.BSON.deserialize(data);
    //     if (data_.success) {
    //        await this.sendCoin();
    //     } else {
    //       if (data_.status_code == 401) {
    //         localStorage.removeItem("token");
    //         window.location.href = "/login";
    //       } else {
    //         window.location.reload();
    //       }
    //     }
    //   } catch (error) {
    //     window.location.reload();
    //   }
    // });

    setInterval(async () => {
      await this.sendCoin();
    }, 3000);

    this.socket.on("get-coin", (data: any) => {
      try {
        const data_ = this.BSON.deserialize(data);
        if (data_.success) {
          if (data_.data.length > 0) {
            for (let item of data_.data) {
              const coin = this.coins.find((x: any) => x.symbol == item.symbol);
              if (coin) {
                coin.price = item.price;
                coin.CHANGEPCT24HOUR = item.CHANGEPCT24HOUR;
                coin.CIRCULATINGSUPPLY = item.CIRCULATINGSUPPLY;
                coin.MKTCAP = item.MKTCAP;
                coin.CHANGEPCTHOUR = item.CHANGEPCTHOUR;
              }
            }
          }
        } else {
          if (data_.status_code == 401) {
            localStorage.removeItem("token");
            window.location.href = "/login";
          } else {
            window.location.reload();
          }
        }
      } catch (error) {
        window.location.reload();
      }
    });
  },
  methods: {
    async clickDialogWallet() {
      this.menu = true;
      await this.loadWallet();
      await this.disconnetCoin();
      await this.sendCoin();
    },
    async disconnetCoin() {
      this.socket.emit("disconnect-coin", {
        token: AuthApi.getToken(),
      });
    },
    async sendCoin() {
      this.socket.emit("send-coin", {
        token: AuthApi.getToken(),
      });
    },
    async back() {
      if (this.type == "chooseCoin_deposit") {
        this.tab = "home";
      } else if (this.type == "chain_deposit") {
        this.tab = "choose_token";
        this.type = "chooseCoin_deposit";
      } else if (this.type == "deposit") {
        this.tab = "home";
        this.type = "";
      } else if (this.type == "chooseCoin_swap_from") {
        this.tab = "swap";
        this.type = "";
      } else if (this.type == "chooseCoin_swap_to") {
        this.tab = "swap";
        this.type = "";
      } else if (this.type == "swap") {
        this.loadWallet();
        this.loadCoins();
        this.tab = "home";
        this.type = "";
      } else if (this.type == "deposit") {
        this.tab = "home";
        this.type = "";
      } else if (this.type == "send") {
        this.tab = "home";
        this.type = "";
      } else if (this.type == "chooseToken_send") {
        this.tab = "send";
        this.type = "send";
      } else if (this.type == "chooseChain_send") {
        this.tab = "send";
        this.type = "send";
      } else if (this.type == "detailsToken") {
        this.tab = "home";
        this.type = "home";
      } else {
        this.tab = "home";
        this.type = "home";
      }
    },
    async loadWallet() {
      const responsive = await WalletApi.getWallet();
      if (responsive.success) {
        const transactions = responsive.data;
        for (let item of transactions) {
          let balance = await this.coins.find((x: any) => x.symbol == item[0]);
          if (balance) {
            if (balance.my !== item[1]) {
              this.loadingAnim = "xyz-out";
              balance.my = item[1];
              setTimeout(() => {
                this.loadingAnim = "xyz-in";
              }, 500);
            }
          }
        }
      }
    },
    async loadFee() {
      const responsive = await WalletApi.getFee();
      if (responsive.success) {
        this.fee = responsive.data;
        this.$emit("feeEmit", responsive.data);
      }
    },
    async loadCoins() {
      this.isLoading = true;
      const responsive = await WalletApi.getCoins();
      if (responsive.success) {
        this.coins = responsive.data;
        let mainCoin = responsive.data.find((x: any) => x.isMain == true);
        this.main = mainCoin;
        this.isLoadingCoin = false;

        this.symbolTo = mainCoin;
        this.symbolFrom = responsive.data[1];

        this.$emit("mainCoinEmit", mainCoin);
        this.$emit("coinsEmit", responsive.data);
      }
      this.isLoading = false;
    },
    title(tab: string) {
      if (tab == "swap") {
        return "Chuyển đổi";
      } else if (tab == "choose_token") {
        return "Chọn token thanh toán";
      } else if (tab == "notification") {
        return "Thông báo giao dịch";
      } else if (tab == "deposit") {
        return "Nạp tiền mã hóa";
      } else if (tab == "choose_chain") {
        return "Chọn mạng";
      } else if (tab == "send") {
        return "Gửi tiền mã hóa";
      } else {
        return "";
      }
    },
    formatPrice(price: number) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(price);
    },
  },
});
</script>

<style>
.v-menu__content--fixed {
  margin-top: 10px !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
