<template>
  <div class="text-center mt-2">
    <div class="d-flex justify-center align-center">
      <v-img
        width="20%"
        v-if="type == 'p2p'"
        src="@/assets/empty.svg"
        alt="nftgame"
        loading="lazy"
        decoding="async"
      />
      <img
        alt="nftgame"
        loading="lazy"
        decoding="async"
        :width="width ? width + '%' : '30%'"
        v-else
        src="@/assets/empty.svg"
      />
    </div>
    <v-container v-if="text" style="font-size: 16px"> {{ text }} </v-container>
    <v-container v-else-if="type == 'chain'">
      Không có mạng nào được tìm thấy
    </v-container>
    <v-container v-else-if="type == 'transactions'">
      Hiện tại không có giao dịch nào.
    </v-container>
    <v-container v-else-if="type == 'notifications'">
      Hiện tại bạn không có thông báo nào.
    </v-container>
    <v-container v-else-if="type == 'p2p'" style="font-size: 20px">
      không có giao dịch nào được tìm thấy.
    </v-container>
    <v-container v-else> Không có tiền mã hóa nào được tìm thấy </v-container>
  </div>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["type", "width", "text"],
  data: () => ({}),
});
</script>
