<template>
  <div>
    <v-app-bar
      app
      :color="type == 'room' ? '#363636' : default_.color.main"
      height="85"
      dark
      style="box-shadow: none"
     

    >
      <v-app-bar-nav-icon
        v-if="size.onsize <= 960 && size.onsize > 650"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        @click="to('/')"
        style="cursor: pointer"
        v-if="size.onsize > 1088"
      >
        <v-img
          width="150"
          src="../assets/Images/LOGO.png"
          alt="nftgame"
          loading="lazy"
          decoding="async"
        ></v-img>
      </v-toolbar-title>

      <v-toolbar-title
        @click="to('/')"
        style="cursor: pointer"
        v-if="size.onsize > 650 && size.onsize < 960"
      >
        <v-img
          width="100"
          src="../assets/Images/LOGO.png"
          alt="nftgame"
          loading="lazy"
          decoding="async"
        ></v-img>
      </v-toolbar-title>

      <v-toolbar-title
        @click="to('/')"
        style="cursor: pointer"
        v-if="size.onsize < 650"
      >
        <v-img
          width="100"
          src="../assets/Images/LOGO.png"
          alt="nftgame"
          loading="lazy"
          decoding="async"
        ></v-img>
      </v-toolbar-title>

      <!-- <v-menu
        transition="slide-y-transition"
        v-model="menuSearch"
        :close-on-content-click="false"
        nudge-width="100%"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            solo
            v-show="typeHeader != 'room'"
            prepend-inner-icon="fi-rr-search"
            placeholder="Tìm"
            class="ml-6"
            rounded
            dense
            outlined
            height="50"
            v-model="search"
            width="100"
            hide-details=""
            v-if="size.onsize > 1360"
          ></v-text-field>
        </template>
        <v-card dark :width="500">
          <div v-if="filteredItems(collections).length <= 0">
            <empty-component
              text="Không có kết quả tìm kiếm nào"
            ></empty-component>
          </div>
          <div class="scroll-search" v-else>
            <div v-if="isSeach">
              <v-skeleton-loader
                type="list-item-two-line, list-item-two-line,list-item-two-line,list-item-two-line"
              ></v-skeleton-loader>
            </div>
            <v-list v-else three-line>
              <v-list-item
                @click="
                  (menuSearch = false),
                    (search = ''),
                    to(`/collections/details/${item._id}`)
                "
                v-for="item in filteredItems(collections)"
                :key="item._id"
                class="square xyz-in"
                xyz="fade flip-up up-100% front"
              >
                <v-list-item-avatar>
                  <v-img :src="item.logo"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.describe
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-menu> -->

      <span :class="size.onsize > 1088 ? 'ml-10' : ''">
        <MenuComponent
          :type="1"
          :size="size"
          :to="to"
          :path="path"
          @menusEmit="menus = $event"
        />
      </span>
      <v-spacer style="flex-grow: 10 !important"></v-spacer>

      <v-responsive>
        <div class="d-flex">
          <div>
            <v-menu
              transition="slide-y-transition"
              v-model="menuNotification"
              :close-on-content-click="false"
              :nudge-width="size.onsize < 650 ? 900 : 300"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  bordered
                  v-if="user?._id"
                  color="error"
                  size="10"
                  overlap
                  :content="isCheckNofition ? lengthNotification : 0"
                  :value="isCheckNofition && user?._id"
                  offset-x="15"
                  offset-y="20"
                >
                  <span
                    class="d-flex float-end"
                    v-if="user"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn fab small text @click="loadNotification()">
                      <v-icon
                        :class="isCheckNofition ? 'devvn_animation_zoom' : ''"
                        size="25"
                        >mdi-bell</v-icon
                      >
                    </v-btn>
                  </span>
                </v-badge>
              </template>
              <NotificationComponent
                :default_="default_"
                :notifications="notifications"
                :formatDate="formatDate"
                :isLoadingNotification="isLoadingNotification"
                :subString="subString"
                :shortenText="shortenText"
                :to="to"
              />
            </v-menu>
          </div>

          <div class="ml-2">
            <HomeWalletComponent
              v-show="user"
              :formatDollar="formatDollar"
              :user="user"
              :default_="default_"
              :size="size"
              @mainCoinEmit="mainCoin = $event"
              @coinsEmit="coins = $event"
              @feeEmit="fee = $event"
              :formatCryptoNumber="formatCryptoNumber"
              :loadWallet_="loadWallet_"
              :formatDate="formatDate"
              :formatNumber="formatNumber"
              :balance="balance"
              :socket="socket"
              :BSON="BSON"
              :timelineWallet="timelineWallet"
              :isCheckNofition="isCheckNofition"
              :notificationRef="notificationRef"
              :subString="subString"
              :shortenText="shortenText"
            />
            <LoginComponent v-show="!user" :default_="default_" :user="user" />
          </div>

          <div>
            <MenuComponent
              :type="3"
              :size="size"
              :to="to"
              :path="path"
              :default_="default_"
              :logOut="logOut"
              :user="user"
              :isLoadingMenu="isLoadingMenu"
              @menusEmit="menus = $event"
            />
          </div>
        </div>
      </v-responsive>
    </v-app-bar>

    <v-navigation-drawer
      dark
      v-model="drawer"
      fixed
      temporary
      :color="default_.color.main"
    >
      <div
        class="d-flex justify-center align-center"
        style="height: 150px"
        @click="to('/')"
      >
        <img
          width="200"
          src="../assets/Images/LOGO.png"
          alt="nftgame"
          loading="lazy"
          decoding="async"
        />
      </div>
      <div class="pa-2">
        <MenuComponent
          :type="2"
          :size="size"
          :to="to"
          :path="path"
          @menusEmit="menus = $event"
        />
      </div>
    </v-navigation-drawer>

    <audio controls id="music" hidden>
      <source src="@/assets/music.mp3" type="audio/ogg" />
    </audio>

    <v-dialog
      v-model="dialogCancel"
      max-width="400"
      v-if="notificationCancelBroker"
    >
      <v-toolbar dark color="#363636">
        <v-btn icon dark @click="dialogCancel = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ notificationCancelBroker.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card
        color="#363636"
        dark
        style="border-radius: 0px 0px 0px 0px; margin-top: -20px"
      >
        <div class="pa-2">
          <v-card-text style="font-size: 16px">
            {{ notificationCancelBroker.describe }}
          </v-card-text>
          <div style="padding: 0px 10px">
            <v-btn
              @click="dialogCancel = false"
              class="text-none bg_main_main_blue"
              block
              text
            >
              Tiếp tục chọn nhà môi giới
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-bottom-navigation
      v-if="size.onsize < 650 && type_code == 'home'"
      fixed
      height="71"
      :background-color="default_.color.main"
      dark
      :input-value="active"
    >
      <v-btn
        depressed
        @click="to(item.path)"
        style="border-radius: 0px 0px 0px 0px !important"
        v-for="(item, i) of menus"
        :key="i"
      >
        <span
          style="font-size: 10px !important"
          :class="path == item.path ? 'text_th mt-1' : 'mt-1'"
          >{{ item.name }}</span
        >

        <v-icon size="20" :class="path == item.path ? 'text_th' : ''">{{
          item.icon
        }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import Vue from "vue";
import LoginComponent from "@/components/auth/Login.vue";
import HomeWalletComponent from "@/components/wallet/HomeWallet.vue";
import EmptyComponent from "@/components/Empty.vue";
import MenuComponent from "@/components/Menu.vue";
import NotificationComponent from "@/components/Notification.vue";
import CollectionApi from "@/api/collections";
import AuthApi from "@/api/auth";

export default Vue.extend({
  props: [
    "default_",
    "user",
    "size",
    "formatCryptoNumber",
    "to",
    "type",
    "loadWallet_",
    "formatDate",
    "formatNumber",
    "balance",
    "typeHeader",
    "socket",
    "BSON",
    "path",
    "formatDollar",
    "timelineWallet",
    "subString",
    "shortenText",
    "error",
    "type_code",
  ],
  components: {
    LoginComponent,
    HomeWalletComponent,
    EmptyComponent,
    MenuComponent,
    NotificationComponent,
  },
  data: () => ({
    active: true,
    isLoadingMenu: false,
    notificationCancelBroker: null,
    dialogCancel: true,
    isLoadingNotification: true,
    menuNotification: false,
    drawer: null,
    menu2: false,
    menus: [],
    mainCoin: {},
    coins: [],
    fee: {},
    search: "",
    menuSearch: false,
    collections: [],
    isSeach: true,
    notifications: [],
    notificationRef: "",
    notificationNew: [],
    lengthNotification: Number(localStorage.getItem("lengthNotification"))
      ? Number(localStorage.getItem("lengthNotification"))
      : 0,
    isCheckNofition: Boolean(localStorage.getItem("isNotification"))
      ? true
      : false,
  }),
  async mounted() {
    await this.socket.on("notification", (data) => {
      const data_ = this.BSON.deserialize(data);
      if (data_.success) {
        var a = document.getElementById("music");
        a.autoplay = true;
        a.load();
        if (data_.data.ref == "cancel_broker") {
          this.dialogCancel = true;
          this.notificationCancelBroker = data_.data;
        }

        if (this.menuNotification) {
          this.notifications.unshift(data_.data);
          this.notificationRef = data_.data.ref;
        } else {
          this.notificationNew.push(data_.data);
          this.notificationRef = data_.data.ref;
          this.isCheckNofition = true;
          const legth = Number(this.lengthNotification) + 1;
          this.lengthNotification = legth;
          localStorage.setItem("isNotification", true);
          localStorage.setItem("lengthNotification", legth);
        }
      }
    });
  },
  methods: {
    async loadNotification() {
      this.isLoadingNotification = true;
      this.notificationNew = [];
      this.isCheckNofition = false;
      this.lengthNotification = 0;
      let notification = await AuthApi.getNotification();
      this.notifications = notification.data;
      localStorage.removeItem("isNotification");
      localStorage.removeItem("lengthNotification");
      this.isLoadingNotification = false;
    },
    filteredItems(items) {
      return items.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.describe.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    resetIsCheckNofition() {
      this.$emit("isCheckNofitionEmit", false);
    },
    async logOut() {
      this.isLoadingMenu = true;
      const call = await AuthApi.logOut();
      if (call.success) {
        const room = await localStorage.getItem("room");
        if (room) {
          try {
            await BrokerApi.logoutRoom(room);
          } catch (error) {}
        }
        window.location.href = "/login";
        localStorage.removeItem("token");
        localStorage.removeItem("isNotification");
        localStorage.removeItem("lengthNotification");
      } else {
        this.error(call.message);
      }
      this.isLoadingMenu = false;
    },
  },
  watch: {
    async search() {
      if (this.search) {
        if (!this.collections.length) {
          this.isSeach = true;
          const call = await CollectionApi.getCollections("all");
          this.collections = call.data;
          this.isSeach = false;
        }
        this.menuSearch = true;
      } else {
        this.menuSearch = false;
      }
    },
    mainCoin() {
      this.$emit("mainCoinEmit", this.mainCoin);
    },
    coins() {
      this.$emit("coinsEmit", this.coins);
    },
    fee() {
      this.$emit("feeEmit", this.fee);
    },
  },
});
</script>

<style scoped>
.scroll-search {
  overflow: scroll;
  height: 50vh;
}
.devvn_animation_zoom {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  z-index: 9999 !important;
  -webkit-animation: devvn_zoom 1.5s infinite linear;
  -moz-animation: devvn_zoom 1.5s infinite linear;
  -o-animation: devvn_zoom 1.5s infinite linear;
  animation: devvn_zoom 1.5s infinite linear;
  box-shadow: 0 0 0 0 #ffebee;
}
@-webkit-keyframes devvn_zoom {
  70% {
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
@keyframes devvn_zoom {
  70% {
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
@-webkit-keyframes calltrap_spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-moz-keyframes calltrap_spin {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-o-keyframes calltrap_spin {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes calltrap_spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.v-menu__content--fixed {
  margin-top: 10px !important;
}

.footer {
  height: 40px;
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  bottom: 0;
}
</style>
