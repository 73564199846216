import axios from 'axios'
import { checkError } from "../api/error"

class BrokerClass {
    private url = process.env.VUE_APP_BACKEND_URL + '/api/v1/broker';

    public async getBroker() {
        try {
            const response = await axios.get(this.url + '/details', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async myRoom() {
        try {
            const response = await axios.get(this.url + '/my-room', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async logoutRoom(roomId: string) {
        try {
            const response = await axios.post(this.url + '/log-out-room', {
                roomId
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async deposit(amount: number) {
        try {
            const response = await axios.post(this.url + '/deposit', {
                amount
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async reportHistory(data: any) {
        try {
            const response = await axios.post(this.url + '/report-history', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async withdraw(amount: number) {
        try {
            const response = await axios.post(this.url + '/withdraw', {
                amount
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async cancelRoomUser(roomId: string) {
        try {
            const response = await axios.post(this.url + '/cancel-room', {
                roomId
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async cancelRoomBroker(roomId: string) {
        try {
            const response = await axios.post(this.url + '/broker-cancel-room', {
                roomId
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async chat(roomId: string, message: string) {
        try {
            const response = await axios.post(this.url + '/chat', { roomId, message }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }

    public async chatImage(data: any) {
        try {
            const response = await axios.post(this.url + '/chat-image', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {

            return err.response.data;
        }
    }

    public async reportRoom(title: string, desc: string, roomId: string) {
        try {
            const response = await axios.post(this.url + '/report-room', { title, desc, roomId }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async disconnect(type: string) {
        try {
            const response = await axios.post(this.url + '/disconnect', { type }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }


    public async ActionBSB(method: string, roomId: string) {
        try {
            const response = await axios.post(this.url + '/action-bsb', { method, roomId }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getRoom(roomId: string) {
        try {
            const response = await axios.get(this.url + '/room', {
                params: {
                    roomId
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getBrokers() {
        try {
            const response = await axios.get(this.url + '/list', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async buysell(type: string, brokerId: string, amount: number, des: string) {
        try {
            const response = await axios.post(this.url + '/buy-sell', {
                type,
                brokerId,
                amount,
                des
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async addAmountDay(amount: number) {
        try {
            const response = await axios.post(this.url + '/add-amount-day', {
                amount,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async updateInfoBroker(desPayment: number, noteRose: string, phone: string) {
        try {
            const response = await axios.post(this.url + '/update-info', {
                desPayment,
                noteRose,
                phone
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async regsiterBroker(price: number, amountDay: number, noteRose: string, phone: string) {
        try {
            const response = await axios.post(this.url + '/register', { price, amountDay, noteRose, phone }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }
}

export default new BrokerClass()