<template>
  <div style="font-size: 16px">
    <div>
      <div style="padding: 10px 0px; margin-top: -10px">
        Khả dụng:
        <b class="text_th">{{ formatCryptoNumber(symbolFrom.my) }}</b>
        {{ symbolFrom.symbol }}
      </div>

      <v-sheet
        style="border-radius: 10px"
        class="square xyz-in"
        xyz="fade flip-up up-100% front"
      >
        <div class="pa-4">
          <v-row no-gutters>
            <v-col cols="8">
              <div
                class="d-flex text-center"
                @click="chooseTokenFrom()"
                style="cursor: pointer"
              >
                <div>
                  <v-avatar size="30">
                    <v-img
                      :alt="symbolFrom.symbol"
                      loading="lazy"
                      decoding="async"
                      :src="symbolFrom.image"
                    ></v-img>
                  </v-avatar>
                </div>
                <div class="ml-2">
                  <h3>{{ symbolFrom.symbol }}</h3>
                </div>
                <div class="ml-1" v-if="type != 'one'">
                  <v-icon size="15">fi-rs-caret-down</v-icon>
                </div>
              </div>
              <div class="mt-2" style="font-size: 14px; opacity: 0.8">
                <div style="width: 220px">
                  Số dư:
                  <span class="text_th">
                    {{
                      symbolFrom.my < 0.1
                        ? symbolFrom.my
                        : formatCryptoNumber(symbolFrom.my)
                    }}</span
                  >
                  {{ symbolFrom.symbol }}
                </div>
              </div>
            </v-col>

            <v-col cols="4" class="text-end">
              <h3>
                <v-text-field
                  hide-details=""
                  class="text-end"
                  width="100%"
                  autofocus
                  v-model="amountFrom"
                  solo
                  hide-spin-buttons
                  type="number"
                  placeholder="0"
                ></v-text-field>
              </h3>
              <div
                @click="maxAmountFrom"
                style="
                  font-size: 15px;
                  opacity: 0.7;
                  margin-top: -10px;
                  cursor: pointer;
                  color: yellow;
                "
              >
                Tối đa
              </div>
            </v-col>
          </v-row>
        </div>
      </v-sheet>

      <div
        xyz="fade flip-up up-100% front"
        class="justify-center d-flex square xyz-in"
        style="
          position: relative;
          z-index: 10;
          margin-top: -10px;
          cursor: pointer;
        "
      >
        <div
          style="
            background-color: rgb(19 19 19);
            width: 50px;
            height: 50px;
            border-radius: 50%;
          "
          class="d-flex justify-center"
          @click="change()"
        >
          <v-icon size="20" class="text_th" v-if="type != 'one'"
            >fi fi-sr-arrows-retweet</v-icon
          >

          <v-icon size="20" v-else>fi fi-rr-arrow-small-down</v-icon>
        </div>
      </div>

      <v-sheet
        style="border-radius: 10px; margin-top: -10px"
        class="square xyz-in"
        xyz="fade flip-up up-100% back"
      >
        <div class="pa-4">
          <v-row>
            <v-col cols="7">
              <div
                class="d-flex text-center"
                @click="chooseTokenTo()"
                style="cursor: pointer"
              >
                <div>
                  <v-avatar size="30">
                    <v-img
                      :alt="symbolTo.symbol"
                      loading="lazy"
                      decoding="async"
                      :src="symbolTo.image"
                    ></v-img>
                  </v-avatar>
                </div>
                <div class="ml-2">
                  <h3>{{ symbolTo.symbol }}</h3>
                </div>
                <div class="ml-1" v-if="type != 'one'">
                  <v-icon size="15">fi-rs-caret-down</v-icon>
                </div>
              </div>
              <div class="mt-2" style="font-size: 14px; opacity: 0.8">
                Số dư:
                <span class="text_th">
                  {{
                    symbolTo.my < 0.1
                      ? symbolTo.my
                        ? symbolTo.my
                        : 0
                      : formatCryptoNumber(symbolTo.my ? symbolTo.my : 0)
                  }}</span
                >
                {{ symbolTo.symbol }}
              </div>
            </v-col>

            <v-col cols="5" class="text-end">
              <h3>
                {{
                  priceSwap(symbolFrom, symbolTo)
                    ? formatCryptoNumber(priceSwap(symbolFrom, symbolTo))
                    : 0
                }}
              </h3>
              <div style="font-size: 15px; opacity: 0.7"></div>
            </v-col>
          </v-row>
        </div>
      </v-sheet>

      <div class="mt-2 text-center" style="font-size: 14px; opacity: 0.7">
        <div v-if="price(symbolFrom, symbolTo) > 0">
          1 {{ symbolFrom.symbol }} ≈
          {{
            price(symbolFrom, symbolTo)
              ? formatCryptoNumber(price(symbolFrom, symbolTo))
              : 0
          }}
          {{ symbolTo.symbol }}
        </div>

        <div v-else>Chưa thể quy đổi đồng</div>
      </div>

      <v-sheet
        color="rgb(19 19 19)"
        style="border-radius: 10px; border: 1px solid #1e1e1e"
        class="mt-2"
      >
        <div class="pa-4" style="font-size: 14px; opacity: 0.8">
          <v-row no-gutters>
            <v-col cols="6" style="opacity: 0.8"> Bạn sẽ trả </v-col>

            <v-col cols="6" class="text-end" style="opacity: 0.8">
              Bạn sẽ nhận
            </v-col>
            <v-col cols="6" class="font-weight-bold">
              <div>
                {{ formatCryptoNumber(this.amountFrom) }}
                {{ symbolFrom.symbol }}
              </div>
            </v-col>
            <v-col cols="6" class="text-end">
              {{ formatCryptoNumber(priceSwap(symbolFrom, symbolTo)) }}

              {{ symbolTo.symbol }}
            </v-col>
          </v-row>
        </div>
      </v-sheet>

      <v-sheet
        class="square xyz-in"
        xyz="fade flip-up front-5"
        style="border-radius: 10px"
        v-if="error"
      >
        <v-row no-gutters class="mt-2">
          <v-col cols="12">
            <div class="pa-3 error--text">{{ error }}</div>
          </v-col>
        </v-row>
      </v-sheet>

      <div class="mt-4">
        <v-btn
          class="bg_main_main_blue"
          style="border-radius: 10px"
          :disabled="symbolFrom.my > 0 ? false : true"
          block
          x-large
          depressed
          @click="swap()"
          v-if="!isLoading"
        >
          {{ symbolFrom.my > 0 ? "Hoán đổi" : "Không đủ " + symbolFrom.symbol }}
        </v-btn>

        <v-btn
          v-else
          style="border-radius: 10px"
          block
          disabled
          x-large
          depressed
        >
          Đang xử lý
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from "moment";
import Vue from "vue";
import WalletApi from "@/api/wallet";

export default Vue.extend({
  props: [
    "default_",
    "main",
    "symbolFrom",
    "symbolTo",
    "fee",
    "formatCryptoNumber",
    "formatNumber",
    "type",
  ],
  data: () => ({
    amountFrom: "" as any,
    isLoading: false,
    error: "" as any,
    time: 5,
  }),
  created() {},
  watch: {
   
  },
  methods: {
     amountFromRegex() {
      const regex = /(?=.*[a-zA-Z])|(?=.*[^a-zA-Z0-9\s])/;
      if (this.amountFrom < 0 || !regex.test(this.amountFrom)) {
        this.amountFrom = this.symbolFrom.my;
      }
    },
    maxAmountFrom() {
      this.amountFrom = this.symbolFrom.my;
    },
    priteMain(price: any) {
      return (price.price / this.main.price) * price.my;
    },
    price(symbolFrom: any, symbolTo: any) {
      return symbolFrom.price / symbolTo.price;
    },
    priceSwap(symbolFrom: any, symbolTo: any) {
      const price =
        (symbolFrom.price / symbolTo.price) *
        (this.amountFrom - this.amountFrom * (this.fee.swap / 100));
      return price;
    },
    async checkError() {
      try {
        if (this.amountFrom <= 0) {
          this.errorFunction("Vui lòng nhập số lượng.");
          return false;
        }

        if (this.symbolFrom.symbol == this.symbolTo.symbol) {
          this.errorFunction("Không hoán đổi 2 đồng giống nhau.");
          return false;
        }

        if (this.symbolFrom.symbol == this.main.symbol) {
          if (this.amountFrom < this.fee.minimum_swap_coin_main) {
            this.errorFunction(
              `Tối thiểu ${this.fee.minimum_swap_coin_main} ${this.main.symbol} để hoán đổi.`
            );
            return false;
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    },
    async swap() {
      this.isLoading = true;

      const validate: any = await this.checkError();
      if (validate) {
        const res = await WalletApi.swap(
          this.symbolFrom.symbol,
          this.symbolTo.symbol,
          this.amountFrom
        );
        if (res.success) {
          this.amountFrom = "";
          this.$emit("tabEmit", "notification");
          this.$emit("swapChangeEmit", {
            ...res.data,
            ...{ date: moment() },
          });
        } else {
          this.errorFunction(res.message);
        }
      }
      this.isLoading = false;
    },
    async change() {
      const sf = this.symbolFrom;
      const st = this.symbolTo;

      this.$emit("symbolFromEmit", st);
      this.$emit("symbolToEmit", sf);
    },
    async errorFunction(message: string) {
      this.error = message;
      setTimeout(() => {
        this.error = "";
      }, 2000);
    },
    async chooseTokenFrom() {
      this.$emit("tabEmit", "swap_from");
    },
    async chooseTokenTo() {
      this.$emit("tabEmit", "swap_to");
    },
  },
});
</script>

<style>
.v-text-field.v-text-field--solo .v-input__control input {
  caret-color: auto;
  text-align: end;
  box-shadow: none !important;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
  margin-left: 10px;
  margin-top: -5px;
  font-size: 18px;
}
</style>
