<template>
  <div>
    <div class="d-flex justify-center align-center text-center">
      <div>
        <div style="font-size: 40px">
          {{ formatCryptoNumber(symbolFrom.my) }}
        </div>
        <div style="opacity: 0.5">
          ≈
          {{
            formatCryptoNumber((symbolFrom.price / main.price) * symbolFrom.my)
          }}
          {{ main.symbol }}
        </div>

        <v-row class="text-center mt-2">
          <v-col cols="6">
            <v-btn fab depressed @click="clickChangeClick('send_details')">
              <v-icon>fi fi-rr-arrow-small-up</v-icon>
            </v-btn>
            <div class="mt-2">Rút</div>
          </v-col>

          <v-col
            cols="6"
            @click="clickChangeClick('chooseCoin_deposi_details')"
          >
            <v-btn fab depressed>
              <v-icon>fi fi-rr-arrow-small-down</v-icon>
            </v-btn>
            <div class="mt-2">Nạp</div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-divider class="mt-5"></v-divider>

    <div>
      <div v-if="isLoadingTransaction">
        <v-skeleton-loader
          v-for="n in 6"
          :key="n"
          class="mt-2"
          style="border-radius: 10px"
          type="list-item-two-line"
          :width="size.onsize > 650 ? '400' : '380'"
        ></v-skeleton-loader>
      </div>
      <TransactionComponent
        v-else
        :formatDate="formatDate"
        :formatCryptoNumber="formatCryptoNumber"
        :transactions="transactions"
        :coin="symbolFrom"
        :default_="default_"
        :shortenText="shortenText"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import TransactionComponent from "@/components/wallet/Transaction.vue";

export default Vue.extend({
  props: [
    "symbolFrom",
    "main",
    "formatCryptoNumber",
    "transactions",
    "formatDate",
    "isLoadingTransaction",
    "default_",
    "shortenText",
    "size",
  ],
  components: {
    TransactionComponent,
  },
  data: () => ({}),
  methods: {
    formatNumberWithDots(number: number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clickChangeClick(tab: string) {
      this.$emit("tabEmit", tab);
      this.$emit("coinDepositEmit", this.symbolFrom);
    },
    clickChangeToken(tab: string, item: any) {
      this.$emit("tabEmit", tab);
      this.$emit("coinEmit", item);
    },
  },
});
</script>
