<template>
  <span>
    <v-btn
      large
      rounded
      class="ml-2 text-none bg_main_main_blue"
      :to="{ path: '/login' }"
    >
      Đăng nhập
    </v-btn>

    <v-dialog v-model="dialog" max-width="400" dark>
      <v-card
        class="text-center"
        :color="default_.color.main"
        style="border-radius: 30px"
      >
        <v-card-text>
          <div class="pa-10">
            <div class="square xyz-in" xyz="fade down flip-up">
              <h1>Đăng nhập</h1>
              <div class="mt-5">
                Choose one of available wallet providers or create a new wallet.
              </div>
            </div>
          </div>

          <div>
            <div class="square xyz-in" xyz="fade down flip-up">
              <!-- <v-btn rounded large block text @click="login()">
                <v-avatar size="20">
                  <v-img width="10" src="@/assets/google.png"></v-img>
                </v-avatar>
                <span class="ml-2"> Đăng nhập bằng Google</span>
              </v-btn> -->

              <!-- <div id="buttonDiv"></div> -->
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <div id="buttonDiv"></div> -->
  </span>
</template>

<script>
import Vue from "vue";
import AuthApi from "@/api/auth";

export default Vue.extend({
  props: ["default_", "user"],
  components: {},
  data: () => ({
    dialog: false,
    isLogin: true,
  }),
  async created() {},
  mounted() {
    async function handleCredentialResponse(response) {
      const login_google = await AuthApi.loginGoogle(response.credential);
      if (login_google.success) {
        localStorage.setItem("token", login_google.data.token);
        window.location.reload();
      }
    }

    if (!this.user?._id) {
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_API_CLIENT_GOOGLE,
        callback: handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outline",
          size: "large",
          width: 309,
          height: 50,
        } // customization attributes
      );
      google.accounts.id.prompt(); // also display the One Tap dialog
    }
  },
  methods: {
    async login() {
      this.$router.push({
        path: "/login",
      });
    },
  },
});
</script>

<style>
#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
</style>
