export const checkError = (status: number) => {
    status = Number(status)
    switch (status) { 
        case 429:
            localStorage.removeItem("token");
            alert("Bạn đã reload quá nhiều, vui lòng quay lại sau 1 phút")
            window.location.href = "/too-many"
            break;
        default:
            return false;
    }

    return true;
}