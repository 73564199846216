<template>
  <v-app onbeforeunload="">
    <HeaderComponent
      @mainCoinEmit="mainCoin = $event"
      :user="user"
      :default_="default_"
      :size="size"
      :formatCryptoNumber="formatCryptoNumber"
      :to="to"
      :formatDate="formatDate"
      :formatNumber="formatNumber"
      :notifications="notifications"
      :isCheckNofition="isCheckNofition"
      @isCheckNofitionEmit="isCheckNofition_ = $event"
      typeHeader="room"
      type="room"
      :BSON="BSON"
      :path="path"
      :formatDollar="formatDollar"
      :socket="socket"
      :timelineWallet="timelineWallet"
      :subString="subString"
      :shortenText="shortenText"
      :error="error"
    />

    <v-main style="background-color: var(--bg-chat) !important">
      <v-sheet dark :color="default_.color.main">
        <router-view
          :formatDate="formatDate"
          :formatCryptoNumber="formatCryptoNumber"
          :mainCoin="mainCoin"
          :default_="default_"
          :user="user"
          :size="size"
          :socket="socket"
          :to="to"
          :formatDollar="formatDollar"
          :error="error"
          :shortenText="shortenText"
          :success="success"
        />

        <v-dialog
          v-model="dialogCancel"
          max-width="400"
          v-if="notificationCancelBroker"
        >
          <v-card :color="default_.color.main" dark>
            <v-card-title class="text-h5">
              {{ notificationCancelBroker.name }}
            </v-card-title>

            <v-card-text style="font-size: 18px">
              {{ notificationCancelBroker.describe }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                class="text-none"
                text
                @click="dialogCancel = false"
              >
                Hủy
              </v-btn>

              <v-btn
                @click="to('/broker/list')"
                color="green darken-1"
                class="text-none"
                text
              >
                Tiếp tục chọn nhà môi giới
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-sheet>
    </v-main>
  </v-app>
</template>

<script>
import HeaderComponent from "@/components/Header.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  props: [
    "default_",
    "user",
    "size",
    "socket",
    "notifications",
    "notificationCancelBroker",
    "formatNumber",
    "formatDate",
    "formatCryptoNumber",
    "isCheckNofition",
    "error",
    "success",
    "BSON",
    "path",
    "formatDollar",
    "to",
    "timelineWallet",
    "subString",
    "shortenText",
    "success",
  ],
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data: () => ({
    mainCoin: {},
    isCheckNofition_: null,
  }),
  async created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
  watch: {
    isCheckNofition_() {
      this.$emit("isCheckNofitionEmit", false);
    },
  },
};
</script>

<style>
.v-application--wrap {
  min-height: 0% !important;
}

.container-main {
  max-width: 1300px !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90%;
  border-radius: 20px;
}
</style>
