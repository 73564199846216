<template>
  <div class="d-flex justify-center align-center text-center" :style="`height: ${height}px;`">
    <div>
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div>Chờ xíu nhé, nếu quá lâu xin tải lại trang</div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["height"],
  data: () => ({}),
});
</script>
