<template>
  <div :class="`pa-2 ${symbolFrom?.symbol != main?.symbol ? '' : 'sc'}`">
    <v-sheet
      style="border-radius: 20px"
      class="square xyz-in w-100"
      xyz="fade down flip-up"
    >
      <div class="pa-5">
        <div class="text-yellow" style="font-size: 12px">Nếu thấy số dư ví có biến động hoặc bất thường, vui lòng không thực hiện các lệnh P2P, rút tiền hoặc các dịch vụ có liên quan đến số dư. Xin hãy báo ngay đến admin để được xử lý kịp thời.</div>
        <v-row no-gutters>
          <v-col cols="2">
            <center>
              <v-badge
                :value="symbolFrom?.symbol != main?.symbol"
                avatar
                bordered
                bottom
                offset-x="10"
                offset-y="15"
              >
                <template v-slot:badge>
                  <v-avatar>
                    <v-img
                      :alt="chain.chain_coin.name"
                      loading="lazy"
                      decoding="async"
                      width="10"
                      :src="chain.chain_coin.image"
                    ></v-img>
                  </v-avatar>
                </template>
                <v-avatar size="30">
                  <v-img
                    :alt="symbolFrom.symbol"
                    loading="lazy"
                    decoding="async"
                    :src="symbolFrom.image"
                  ></v-img>
                </v-avatar>
              </v-badge>
            </center>
          </v-col>

          <v-col cols="9">
            <div v-if="symbolFrom?.symbol != main?.symbol">
              <div class="d-flex">
                <h2 style="margin-top: -5px">{{ symbolFrom.symbol }}</h2>
                <v-chip class="ml-2" small label>{{
                  chain.chain_coin.name
                }}</v-chip>
              </div>

              <v-row>
                <v-col cols="9">
                  <div style="width: 200px">
                    {{ deposit.address }}
                  </div>
                </v-col>

                <v-col cols="3">
                  <v-btn
                    icon
                    class="ml-2 float-end"
                    @click="copy(deposit.address)"
                  >
                    <v-icon>fi-rr-copy-alt</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div
                v-show="isCopy"
                :class="`square ${isCopy ? 'xyz-in' : 'xyz-out'}`"
                xyz="fade down flip-up"
              >
                <div class="mt-2 success--text">Copy thành công địa chỉ ví</div>
              </div>
            </div>

            <div v-else>
              <div>
                <h2 style="margin-top: -5px">{{ symbolFrom.symbol }}</h2>

                <v-row>
                  <v-col cols="9">
                    <div style="font-size: 14px">
                      Nội dung chuyển khoản: {{ main.symbol }}NFTGEX{{
                        user.code
                      }}
                    </div>
                  </v-col>

                  <v-col cols="3">
                    <v-btn
                      icon
                      class="ml-2 float-end"
                      @click="copy(main.symbol + 'NFTGEX' + user.code)"
                    >
                      <v-icon>fi-rr-copy-alt</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <div
                  v-show="isCopy"
                  :class="`square ${isCopy ? 'xyz-in' : 'xyz-out'}`"
                  xyz="fade down flip-up"
                >
                  <div class="mt-2 success--text">
                    Copy thành công nội dung chuyển khoản.
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-divider class="mt-5"></v-divider>

        <div v-if="symbolFrom.symbol != main.symbol">
          <div
            class="text-center mt-4"
            style="font-size: 16px; opacity: 0.8; padding: 0px 60px"
          >
            Chỉ gửi tài sản mạng <b>{{ chain.chain_coin.name }}</b> tới địa chỉ
            này
          </div>

          <div class="d-flex justify-center mt-5" v-if="deposit.address">
            <div>
              <qrcode-vue
                style="border-radius: 20px"
                foreground="#1e1e1e"
                :value="this.deposit.address"
                :size="size"
                level="L"
              ></qrcode-vue>
            </div>
          </div>
        </div>

        <div v-else>
          <LoadingComponent v-if="!deposit.address" />

          <div class="mt-2">
            <div v-if="isBank">
              <v-skeleton-loader
                height="220"
                style="border-radius: 10px"
                type="image"
              ></v-skeleton-loader>
            </div>

            <div v-else>
              <div v-if="bank.length <= 0">
                <div class="text-center font-weight-bold">
                  Hệ thống nạp đang bảo trì, vui lòng quay lại sau. Những giao dịch đã nạp mà chưa nhận sẽ được cộng lại khi bảo trì hoàn tất.
                </div>
              </div>

              <div v-else>
                <v-expansion-panels
                  v-model="depositModel"
                  class="w-100 mt-2"
                  style="box-shadow: 0px 0px 0px 0px !important"
                >
                  <v-expansion-panel v-for="item in bank" :key="item._id">
                    <v-expansion-panel-header>
                      Ngân hàng {{ item.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="font-size: 14px">
                      <center>
                        <img
                          :alt="item.name"
                          loading="lazy"
                          decoding="async"
                          style="border-radius: 10px"
                          width="70%"
                          height="200"
                          :src="`https://img.vietqr.io/image/${item.name}-${item.numberAccount}-compact.png?addInfo=${main.symbol}NFTGEX${user.code}`"
                        />
                      </center>
                      <div class="text-center font-weight-bold">
                        Người nhận: {{ item.fullName }}
                      </div>
                      <div class="text-center mt-1">
                        Nạp tối thiểu
                        <span class="text_th">{{
                          formatCryptoNumber(item.minimumDeposit)
                        }}</span>
                        VND
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-sheet>

    <div class="text-center mt-2">
      <div style="padding: 0px 15px" v-if="deposit?.contract?.length > 15">
        <v-row no-gutters style="font-size: 13px">
          <v-col cols="8" class="text-start" style="opacity: 0.6">
            Địa chỉ hợp đồng kết thức bằng
          </v-col>

          <v-col cols="4" class="text-end font-weight-bold">
            <a
              target="_blank"
              style="color: white; text-decoration: none"
              :href="chain.chain_coin.scan + '/' + deposit.contract"
            >
              {{ deposit.contract.slice(-5) }}
              <v-icon size="12">fi-rr-angle-small-right</v-icon></a
            >
          </v-col>
        </v-row>
      </div>

      <div class="pa-2" v-if="symbolFrom.symbol != main.symbol">
        Hệ thống sẽ mất từ
        <span class="text_th font-weight-bold">1 - 5 phút</span> để cập nhật
        thông tin, nếu vượt quá thời gian này vui lòng liên hệ để được kiểm tra.
      </div>

      <div v-else class="text-start" style="font-size: 14px">
        <div class="red--text text--lighten-1">
          - Vui lòng điền chính xác nội dung chuyển khoản để thực hiện nạp tiền
          tự động.
        </div>

        <div>
          - Tiền sẽ vào tài khoản trong vòng 1-10 phút kể từ khi giao dịch
          thành công. Tuy nhiên đôi lúc do một vài lỗi khách quan, tiền có thể
          sẽ vào chậm hơn một chút.
        </div>

        <div>
          - Nếu quá lâu không thấy cập nhật số dư, Vui lòng liên hệ hỗ trợ viên.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import QrcodeVue from "qrcode.vue";
import LoadingComponent from "@/components/Loading.vue";
import WalletApi from "@/api/wallet";

export default Vue.extend({
  components: {
    LoadingComponent,
    QrcodeVue,
  },
  props: [
    "default_",
    "coins",
    "loadingAnim",
    "main",
    "formatPrice",
    "symbolFrom",
    "symbolTo",
    "fee",
    "deposit",
    "chain",
    "user",
    "formatCryptoNumber",
  ],
  data: () => ({
    isCopy: false,
    isLoading: true,
    value: "",
    size: 250,
    depositModel: 0,
    bank: [],
    isLoadingDeposit: false,
    isBank: true,
  }),
  mouted() {},
  methods: {
    async loadBank() {
      this.isBank = true;
      const call = await WalletApi.getBank();
      if (call.success) {
        this.bank = call.data;
      } else {
        if (this.$route.path !== "/error") {
          this.$router.push({
            path: "/error",
          });
        }
      }
      this.isBank = false;
    },
    async copy(text) {
      this.isCopy = true;

      await navigator.clipboard.writeText(text);

      setTimeout(() => {
        this.isCopy = false;
      }, 1500);
    },
  },
  watch: {
    deposit() {
      this.isLoadingDeposit = true;
      if (this.symbolFrom.symbol == this.main.symbol) {
        this.loadBank();
      }
      this.isLoadingDeposit = false;
    },
  },
});
</script>

<style scoped>
.scroll-deposit {
  overflow: auto;
}

.sc {
  overflow: scroll;
  height: 80vh;
}
</style>
