import axios from 'axios'

import { checkError } from "../api/error"

class AuthClass {
    private url = process.env.VUE_APP_BACKEND_URL + '/api/v1/auth';

    public async loginGoogle(token: string) {
        try {
            const response = await axios.post(this.url + '/login-google', { token }, {});
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getNotification(token: string) {
        try {
            const response = await axios.get(this.url + '/get-notification', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async logOut() {
        try {
            const response = await axios.get(this.url + '/log-out', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getProfile() {
        try {
            const response = await axios.get(this.url + '/get-profile', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getOnlines(_id: string[]) {
        try {
            const response = await axios.post(this.url + '/check', {
                _id
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async createPayment(paymentId: string, numberAccount: string, fullname: string) {
        try {
            const response = await axios.post(this.url + '/create-payment', {
                paymentId,
                numberAccount,
                fullname
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async buyKeyBotTelegram(amount: number) {
        try {
            const response = await axios.post(this.url + '/buy-key-bot-telegram', {
                amount
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getKeyBotTelegram() {
        try {
            const response = await axios.get(this.url + '/get-key-bot-telegram', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async deletePayment(paymentId: string) {
        try {
            const response = await axios.post(this.url + '/delete-payment', {
                paymentId
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async getPayment(type = 'all') {
        try {
            const response = await axios.get(this.url + '/get-payment', {
                params: {
                    type
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public async myPayment() {
        try {
            const response = await axios.get(this.url + '/my-payment', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (err: any) {
            checkError(err.response.status)
            return err.response.data;
        }
    }

    public getToken() {
        return localStorage.getItem('token');
    }

}

export default new AuthClass()