<template>
  <div class="font">
    <div>
      <v-app id="inspire" v-resize="onResize">
        <v-main style="position: absolute">
          <v-dialog
            v-model="dialogLoad"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card
              style="border-radius: 0px 0px 0px 0px"
              color="rgba(32,32,32,.9)"
              class="h-100"
              dark
            >
              <div
                class="d-flex justify-content-center align-items-center h-100"
              >
                <div class="socket">
                  <div class="gel center-gel">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c1 r1">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c2 r1">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c3 r1">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c4 r1">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c5 r1">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c6 r1">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>

                  <div class="gel c7 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>

                  <div class="gel c8 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c9 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c10 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c11 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c12 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c13 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c14 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c15 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c16 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c17 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c18 r2">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c19 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c20 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c21 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c22 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c23 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c24 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c25 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c26 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c28 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c29 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c30 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c31 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c32 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c33 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c34 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c35 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c36 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <div class="gel c37 r3">
                    <div class="hex-brick h1"></div>
                    <div class="hex-brick h2"></div>
                    <div class="hex-brick h3"></div>
                  </div>
                  <img
                    alt="nftgame"
                    loading="lazy"
                    decoding="async"
                    class="rotate-circle-image"
                    width="200"
                    src="./assets/Images/icon-2.png"
                  />

                  <!-- <div class="word" data-word="Loading" style="--word-index:0;font-size: 44px;margin-top: 100%;">
                    <span class="char" data-char="L" style="--char-index:0;">L</span>
                    <span class="char" data-char="o" style="--char-index:1;">o</span>
                    <span class="char" data-char="a" style="--char-index:2;">a</span>
                    <span class="char" data-char="d" style="--char-index:3;">d</span>
                    <span class="char" data-char="i" style="--char-index:4;">i</span>
                    <span class="char" data-char="n" style="--char-index:5;">n</span>
                    <span class="char" data-char="g" style="--char-index:6;">g</span>
                  </div> -->
                </div>
              </div>
            </v-card>
          </v-dialog>
        </v-main>
      </v-app>
    </div>
    <NotificationComponent type="error" />
    <NotificationComponent />
    <router-view
      :isCheckNofition="isCheckNofition"
      :default_="default_"
      :user="user"
      :size="size"
      v-if="!dialogLoad"
      :socket="socket"
      :notifications="notifications"
      :formatNumber="formatNumber"
      :formatDate="formatDate"
      :formatCryptoNumber="formatCryptoNumber"
      @isCheckNofitionEmit="isCheckNofition = $event"
      :error="error"
      :success="success"
      :BSON="BSON"
      :path="$route.path"
      :formatDollar="formatDollar"
      :to="to"
      :timelineWallet="timelineWallet"
      :subString="subString"
      :shortenText="shortenText"
    ></router-view>

    <v-dialog v-model="dialogNote" max-width="400">
      <v-card :color="default_.color.main" dark>
        <v-card-title class="text-h5"> Thông báo </v-card-title>

        <v-card-text style="font-size: 18px">
          NFTG Exchange đã tối ưu phần giao dịch trung gian nhà môi giới, giờ
          đây người dùng có thể gửi được hình ảnh siêu tốc độ và các phần lỗi đã
          được xử lý. Rất cảm ơn bạn đã đồng hành và nếu có những vấn đề liên
          quan đến hệ thống vui lòng liên hệ với admin nhé.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="next()" color="green darken-1" class="text-none" text>
            Tiếp tục
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { io } from "socket.io-client";
import { BSON } from "bson";
import moment from "moment";

import default_ from "../core/default";
import UserApi from "@/api/auth";
import BrokerApi from "@/api/broker";

let socket;
try {
  socket = io(process.env.VUE_APP_BACKEND_URL);
} catch (error) {
  console.log(1);
}

import NotificationComponent from "@/components/Notification/Notification.vue";

import Vue from "vue";
export default Vue.extend({
  name: "App",
  data: () => ({
    dialogNote: false,
    path: "",
    dialogBan: false,
    isCheckNofition: false,
    notifications: [],
    socket: socket,
    user: null,
    dialogLoad: true,
    dialogCancel: false,
    isLoading: true,
    notificationCancelBroker: null,
    default_: {
      color: default_.color,
      user: null,
    },
    size: {
      onsize: 0,
      height: 0,
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
    },
    BSON: BSON,
    timelineWallet: null,
    note: "1",
  }),
  components: {
    NotificationComponent,
  },
  async created() {
    // const note = localStorage.getItem("notenote");
    // if (note != this.note) {
    //   this.dialogNote = false;
    // }
    this.getProfile();
  },
  watch: {
    dialogNote() {
      // if (!this.dialogNote) {
      //   localStorage.setItem("notenote", this.note);
      // }
    },
    isCheckNofition() {
      this.isCheckNofition = this.isCheckNofition;
    },
    socket() {},
  },
  mounted() {},
  destroyed() {
    // Hủy lắng nghe khi component bị hủy
  },
  methods: {
    next() {
      this.dialogNote = false;
      localStorage.setItem("notenote", this.note);
    },
    async handleBackButton(event) {},
    subString(text, number) {
      return text.length > number ? text.subString(0, num) : text;
    },
    async disconnect(socket, path) {
      try {
        const token = UserApi.getToken();

        // await socket.emit("disconnet-user", {
        //   token,
        // });

        // socket.emit("disconnect-list-chat", {
        //   token,
        // });

        socket.emit("disconnect-room", { token });

        // socket.emit("disconnect-send-pip-online-room", {
        //   token,
        //   roomId: localStorage.getItem("room"),
        // });

        socket.emit("disconnect-list-room", {
          token,
        });

        const room = localStorage.getItem("room");
        if (room) {
          try {
            await BrokerApi.logoutRoom(room);
          } catch (error) {
            if (this.$route.path !== "/error") {
              this.$router.push({
                path: "/error",
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatDollar(amount) {
      let formattedAmount = amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formattedAmount;
    },
    formatCryptoNumber(number = 0, decimalPlaces = 8) {
      number = Number(number);
      return new Intl.NumberFormat("vi-VN").format(number)
    },
    formatDate(date) {
      var ngayHienTai = moment();
      var ngayTao = moment(date);

      if (ngayHienTai.isSame(ngayTao, "day")) {
        return `Hôm nay ${moment(date).format("HH:mm")}`;
      } else {
        return moment(date).format("DD/MM/YYYY HH:mm");
      }
    },
    async loadNotification(socket) {
      // const token = UserApi.getToken();
      // await socket.emit("disconnect-notification", {
      //   token,
      // });
      // await socket.emit("send-notification", {
      //   token,
      // });
      // await socket.on("get-notification", (data) => {
      //   try {
      //     const data_ = BSON.deserialize(data);
      //     if (data_.success) {
      //       const idTokenRoom = localStorage.getItem("room");
      //       const notificationCancelBroker = data_.data.find(
      //         (x) =>
      //           x.ref == "cancel_broker" &&
      //           x?.describe?.split("#")[1] == idTokenRoom
      //       );
      //       this.notifications = data_.data;
      //       let noti = localStorage.getItem("noti");
      //       if (noti) {
      //         const fNoti = String(data_.data[0]?._id) == noti;
      //         if (!fNoti) {
      //           localStorage.setItem("noti", String(data_.data[0]?._id));
      //           if (
      //             data_.data[0].ref == "deposit_block" ||
      //             data_.data[0].ref == "deposit"
      //           ) {
      //             this.timelineWallet = moment().unix();
      //           }
      //           this.isCheckNofition = true;
      //         } else {
      //           this.isCheckNofition = false;
      //         }
      //       } else {
      //         localStorage.setItem("noti", String(data_.data[0]?._id));
      //         this.isCheckNofition = true;
      //       }
      //       if (notificationCancelBroker) {
      //         this.notificationCancelBroker = notificationCancelBroker;
      //         this.dialogCancel = true;
      //         localStorage.removeItem("room");
      //       }
      //       // const noti = localStorage.getItem("noti");
      //       // if (noti) {
      //       //   const notification_ =
      //       // } else {
      //       //   if (data_.date.length > 0) {
      //       //     this.isCheckNofition = true;
      //       //   }
      //       // }
      //     } else {
      //       this.$notify({
      //         group: "error",
      //         title: data_.message,
      //         text: data_.message,
      //         type: "error",
      //       });
      //     }
      //   } catch (error) {
      //     window.location.reload();
      //   }
      // });
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.resetOnResize();
      this.size.onsize = this.windowSize.x;
      this.size.height = this.windowSize.y;
      if (this.windowSize.x < 600) {
        this.size.xs = true;
      } else if (this.windowSize.x < 960) {
        this.size.sm = true;
      } else if (this.windowSize.x < 1264) {
        this.size.md = true;
      } else if (this.windowSize.x < 2104) {
        this.size.lg = true;
      } else {
        this.size.xl = true;
      }
    },
    resetOnResize() {
      this.size = {
        onsize: 0,
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
      };
    },
    async getProfile() {
      try {
        const get_profile = await UserApi.getProfile();
        if (get_profile !== "Unauthorized") {
          this.disconnect(socket);
          const token = UserApi.getToken();

          await socket.emit("login", {
            token,
          });

          this.socket = socket;
          this.user = get_profile.data;
        }
      } catch (error) {
        if (this.$route.path !== "/error") {
          this.$router.push({
            path: "/error",
          });
        }
      }
      this.dialogLoad = false;
      this.dialogisLoadingLoad = false;
    },
    shortenHash(hash, startLength = 3, endLength = 10) {
      if (hash.length <= startLength + endLength) {
        return hash;
      }
      return hash.slice(0, startLength) + "..." + hash.slice(-endLength);
    },
    shortenText(text = "", startLength = 20, endLength = 20) {
      if (text.length <= startLength + endLength) {
        return text;
      }
      return text.slice(0, startLength) + "..." + text.slice(-endLength);
    },
    formatNumber(num = 0) {
      if (num >= 1000000000000) {
        return (num / 1000000000000).toFixed(1) + "T";
      }
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + "B";
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M";
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + "K";
      }
      return num;
    },
    error(message) {
      this.$notify({
        group: "error",
        title: message,
        text: message,
        type: "error",
      });
    },
    success(message) {
      this.$notify({
        group: "success",
        title: message,
        text: message,
        type: "success",
      });
    },
    to(path) {
      if (path != this.$route.fullPath) {
        window.scrollTo(0, 0);
        if (this.user) {
          this.disconnect(this.socket, path);
        }
        this.$router.push(path);
      }
    },
  },
});
</script>

<style lang="scss">
.v-image__image--cover {
  background-size: 100% 100% !important;
}

.g .v-btn:not(.v-btn--round).v-size--default {
  border-radius: 10px !important;
  text-transform: none;
  margin-left: 2px !important;
}
.bg-main {
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #414343,
    #23252c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #414343,
    #23252c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

figure img {
  width: 100%;
  border-radius: 10px;
  height: auto;
  display: block;
}

picture img {
  width: 100%;
  border-radius: 10px;
  height: auto;
  display: block;
}

.text-green {
  color: #8cff90 !important;
}

.text-red {
  color: #ff8888 !important;
}

.v-text-field {
  border-radius: 10px !important;
}

.v-application .error--text {
  color: #ff6a6a !important;
  caret-color: #ff6a6a !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  border-radius: 10px !important;
  text-transform: none;
}

.card-main {
  border: 1px solid #7de3c3 !important;
  border-radius: 10px !important;
  color: white !important;
  font-weight: 400;

  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #384355,
    #283528
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #384355,
    #283528
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

html {
  background: #131313;
}
:root {
  --base-white: #fff;
  --cyan-500: #51eaff;
  --aqua-500: #1accf6;
  --blue-500: #518cff;
  --pink-500: #dc93ff;
  --magenta-500: #c117c0;
  --orange-500: #f97e32;
  --yellow-500: #fff27d;
  --purple-500: #963be6;
  --red-500: #ef382b;
  --turquoise-500: #62ffd0;
  --green-500: #02e919;
  --gray-50: #f4f9fa;
  --gray-100: #e1e1e1;
  --gray-200: #b8b8b8;
  --gray-300: #888;
  --gray-400: #666;
  --gray-500: #525252;
  --gray-600: #303030;
  --gray-700: #222;
  --gray-800: #191919;
  --gray-900: #111;
  --primary-color: var(--gray-50);
  --secondary-color: var(--gray-200);
  --tertiary-color: var(--gray-500);
  --disabled-color: var(--gray-600);
  --inverse-color: var(--gray-900);
  --critical-color: var(--red-500);
  --warning-color: var(--orange-500);
  --success-color: var(--turquoise-500);
  --text-disabled-color: var(--gray-400);
  --gradient-100: #dc93ff 0%, #518cff 50%, #518cff 100%, #7db9e8 100%,
    #51eaff 100%;
  --gradient-200: #51eaff 0%, #c117c0 50%, #f97e32 100%, #518cff 100%,
    #7db9e8 100%;
  --gradient-300: #fff27d 0%, #f97e32 20%, #c117c0 40%, #963be6 60%, #1accf6 80%,
    #62ffd0 100%, #02e919 100%, #f97e32 100%;
  --gradient-400: #7e1aff 0%, #7983ff 29.99%, #5bffff 74.96%, #42ff55 115.14%;
  --gradient-500: #fff27d 0%, #f97e32 100%, #518cff 100%, #7db9e8 100%;
  --gradient-800: #041d1d -2.92%, #1b0d2e 33.78%, #007b7b 66.34%, #070d31 90.17%;
  --gradient-diagonal-100: linear-gradient(135deg, var(--gradient-100));
  --gradient-diagonal-200: linear-gradient(135deg, var(--gradient-200));
  --gradient-diagonal-300: linear-gradient(135deg, var(--gradient-300));
  --gradient-diagonal-400: linear-gradient(135deg, var(--gradient-400));
  --gradient-diagonal-500: linear-gradient(135deg, var(--gradient-500));
  --gradient-diagonal-800: linear-gradient(135deg, var(--gradient-800));
  --gradient-right-100: linear-gradient(to right, var(--gradient-100));
  --gradient-right-200: linear-gradient(to right, var(--gradient-200));
  --gradient-right-300: linear-gradient(to right, var(--gradient-300));
  --gradient-right-400: linear-gradient(to right, var(--gradient-400));
  --gradient-right-500: linear-gradient(to right, var(--gradient-500));
  --gradient-right-800: linear-gradient(268deg, var(--gradient-800));
  --gradient-left-100: linear-gradient(to left, var(--gradient-100));
  --gradient-left-200: linear-gradient(to left, var(--gradient-200));
  --gradient-left-300: linear-gradient(to left, var(--gradient-300));
  --gradient-left-400: linear-gradient(to left, var(--gradient-400));
  --gradient-left-500: linear-gradient(to left, var(--gradient-500));
  --gradient-left-800: linear-gradient(to left, var(--gradient-800));
  --gradient-up-100: linear-gradient(to top, var(--gradient-100));
  --gradient-up-200: linear-gradient(to top, var(--gradient-200));
  --gradient-up-300: linear-gradient(to top, var(--gradient-300));
  --gradient-up-400: linear-gradient(to top, var(--gradient-400));
  --gradient-up-500: linear-gradient(to top, var(--gradient-500));
  --gradient-up-800: linear-gradient(to top, var(--gradient-800));
  --gradient-down-100: linear-gradient(to bottom, var(--gradient-100));
  --gradient-down-200: linear-gradient(to bottom, var(--gradient-200));
  --gradient-down-300: linear-gradient(to bottom, var(--gradient-300));
  --gradient-down-400: linear-gradient(to bottom, var(--gradient-400));
  --gradient-down-500: linear-gradient(to bottom, var(--gradient-500));
  --gradient-down-800: linear-gradient(to bottom, var(--gradient-800));
  --gradient-for-border: linear-gradient(var(--gray-700), var(--gray-700))
      padding-box,
    linear-gradient(to right, var(--gradient-400)) border-box;
}

.bg_main_main_blue {
  background: linear-gradient(78deg, #3f74d6 0%, #12b883 100%) !important;
}

.bg_main_main_red {
  background: linear-gradient(78deg, #ef5350 0%, #b71c1c 100%) !important;
}

.bg_main_main_green {
  background: linear-gradient(78deg, #26a69a 0%, #004d40 100%) !important;
}

.text-gradient-right-400 {
  background: var(--gray-50);
  background: var(--gradient-right-400);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-selector-caption-background: hsl(0, 0%, 97%);
  --ck-color-selector-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
  table-layout: fixed;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  overflow-wrap: break-word;
  position: relative;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}

.text-yellow {
  background: linear-gradient(
    270deg,
    #ffd600 0%,
    #ffea00 17.47%,
    #ffff00 39.54%,
    #ffff8d 60.49%,
    #f57f17 84.44%,
    #f9a825 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row.no-gutters > .col,
.row.no-gutters > [class*="col-"] {
  padding: 5px !important;
}

/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  position: relative;
  margin-bottom: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content[dir="rtl"] .todo-list .todo-list__label > input {
  left: 0;
  margin-right: 0;
  right: -25px;
  margin-left: -15px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content
  .todo-list
  .todo-list__label.todo-list__label_without-description
  input[type="checkbox"] {
  position: absolute;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > input,
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label
  > span[contenteditable="false"]
  > input {
  cursor: pointer;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label
  > input:hover::before,
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label
  > span[contenteditable="false"]
  > input:hover::before {
  box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.1);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label
  > span[contenteditable="false"]
  > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content[dir="rtl"]
  .todo-list
  .todo-list__label
  > span[contenteditable="false"]
  > input {
  left: 0;
  margin-right: 0;
  right: -25px;
  margin-left: -15px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label
  > span[contenteditable="false"]
  > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label
  > span[contenteditable="false"]
  > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label
  > span[contenteditable="false"]
  > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label
  > span[contenteditable="false"]
  > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content
  .todo-list
  .todo-list__label.todo-list__label_without-description
  input[type="checkbox"] {
  position: absolute;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content img.image_resized {
  height: auto;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol {
  list-style-type: decimal;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol {
  list-style-type: lower-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol {
  list-style-type: lower-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol {
  list-style-type: upper-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol ol {
  list-style-type: upper-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul {
  list-style-type: disc;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul {
  list-style-type: circle;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul {
  list-style-type: square;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul ul {
  list-style-type: square;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* @ckeditor/ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
/* @ckeditor/ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-selector-caption-text);
  background-color: var(--ck-color-selector-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
@media print {
  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}
@media (prefers-reduced-motion: reduce) {
  /* @ckeditor/ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input::before {
    transition: none;
  }
  /* @ckeditor/ckeditor5-list/theme/todolist.css */
  .ck-editor__editable.ck-content
    .todo-list
    .todo-list__label
    > span[contenteditable="false"]
    > input::before {
    transition: none;
  }
}
@media (forced-colors: active) {
  /* @ckeditor/ckeditor5-table/theme/tablecaption.css */
  .ck-content .table > figcaption {
    background-color: unset;
    color: unset;
  }
  /* @ckeditor/ckeditor5-image/theme/imagecaption.css */
  .ck-content .image > figcaption {
    background-color: unset;
    color: unset;
  }
}

.v-main {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
:root {
  --bg-chat: #313338;
}
/* width */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar {
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.v-menu__content--fixed {
  border-radius: 20px !important;
}

.text-success_th {
  background: linear-gradient(
    270deg,
    #12b883 0%,
    #8cfff5 17.47%,
    #dafdff 39.54%,
    #a8fb70 60.49%,
    #c3b1fe 84.44%,
    #2167e8 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}
.lds-spinner {
  color: currentColor;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3.2px;
  left: 36.8px;
  width: 6.4px;
  height: 17.6px;
  border-radius: 20%;
  background: currentColor;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.br-radius {
  border-radius: 20px !important;
}

.button_th {
  background: linear-gradient(98deg, #e53935, #ef9a9a) !important;
}

.button_buy {
  background: linear-gradient(98deg, #004d40, #e0f2f1) !important;
}

.button_sell {
  background: linear-gradient(98deg, #ef9a9a, #e53935) !important;
}

.text_th {
  background: linear-gradient(
    270deg,
    #12b883,
    #8cfff5 17.47%,
    #dafdff 39.54%,
    #a8fb70 60.49%,
    #c3b1fe 84.44%,
    #2167e8
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_sell {
  background: linear-gradient(
    270deg,
    #b71c1c,
    #c62828 17.47%,
    #d32f2f 39.54%,
    #e53935 60.49%,
    #ef5350 84.44%,
    #e57373
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

svg {
  visibility: hidden;
  position: absolute;
}

@keyframes rotateUp {
  0% {
    transform: translate(0, 90%) rotateX(-90deg);
    transform-origin: top center;
  }

  50% {
    transform: translate(0, 0) rotateX(0deg);
    transform-origin: center center;
  }

  100% {
    transform: translate(0, -90%) rotateX(90deg);
    transform-origin: bottom center;
  }
}

.word {
  display: inline-block;
  text-transform: uppercase;
}

.char {
  transform-origin: bottom center;
  display: inline-block;
  position: relative;
  color: transparent;

  &:before,
  &:after {
    content: attr(data-char);
    position: absolute;
    top: 0;
    left: 0;

    background: #182848;
    background: linear-gradient(to bottom, #fff176, #182848);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    transform: translate(0, 100%) rotateX(-90deg);
    transform-origin: top center;
    animation: rotateUp 4s infinite linear normal;
  }

  &:before {
    animation-delay: calc(var(--char-index) * 0.285s);
  }

  &:after {
    animation-delay: calc(calc(var(--char-index) * 0.285s) + 2s);
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2.5rem;
  }
}

.vue-notification {
  padding: 10px;
  margin: 30px !important;
  margin: 0 5px 5px;
  font-size: 17px;
  color: black !important;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.warn {
    background: white !important;
    border-left-color: #f48a06 !important;
  }

  &.error {
    background: white !important;
    border-left-color: #b82e24 !important;
  }

  &.success {
    background: white !important;
    border-left-color: #42a85f !important;
  }
}

.center {
  height: 200px;
  position: relative;
  border: 3px solid green;
}

.center p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rotate-circle-image {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0.5;
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.9;
  }

  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.socket {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
}

.hex-brick {
  background: #abf8ff;
  width: 30px;
  height: 17px;
  position: absolute;
  top: 5px;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.h2 {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.h3 {
  transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}

.gel {
  height: 30px;
  width: 30px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.center-gel {
  margin-left: -15px;
  margin-top: -15px;

  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.c1 {
  margin-left: -47px;
  margin-top: -15px;
}

.c2 {
  margin-left: -31px;
  margin-top: -43px;
}

.c3 {
  margin-left: 1px;
  margin-top: -43px;
}

.c4 {
  margin-left: 17px;
  margin-top: -15px;
}

.c5 {
  margin-left: -31px;
  margin-top: 13px;
}

.c6 {
  margin-left: 1px;
  margin-top: 13px;
}

.c7 {
  margin-left: -63px;
  margin-top: -43px;
}

.c8 {
  margin-left: 33px;
  margin-top: -43px;
}

.c9 {
  margin-left: -15px;
  margin-top: 41px;
}

.c10 {
  margin-left: -63px;
  margin-top: 13px;
}

.c11 {
  margin-left: 33px;
  margin-top: 13px;
}

.c12 {
  margin-left: -15px;
  margin-top: -71px;
}

.c13 {
  margin-left: -47px;
  margin-top: -71px;
}

.c14 {
  margin-left: 17px;
  margin-top: -71px;
}

.c15 {
  margin-left: -47px;
  margin-top: 41px;
}

.c16 {
  margin-left: 17px;
  margin-top: 41px;
}

.c17 {
  margin-left: -79px;
  margin-top: -15px;
}

.c18 {
  margin-left: 49px;
  margin-top: -15px;
}

.c19 {
  margin-left: -63px;
  margin-top: -99px;
}

.c20 {
  margin-left: 33px;
  margin-top: -99px;
}

.c21 {
  margin-left: 1px;
  margin-top: -99px;
}

.c22 {
  margin-left: -31px;
  margin-top: -99px;
}

.c23 {
  margin-left: -63px;
  margin-top: 69px;
}

.c24 {
  margin-left: 33px;
  margin-top: 69px;
}

.c25 {
  margin-left: 1px;
  margin-top: 69px;
}

.c26 {
  margin-left: -31px;
  margin-top: 69px;
}

.c27 {
  margin-left: -79px;
  margin-top: -15px;
}

.c28 {
  margin-left: -95px;
  margin-top: -43px;
}

.c29 {
  margin-left: -95px;
  margin-top: 13px;
}

.c30 {
  margin-left: 49px;
  margin-top: 41px;
}

.c31 {
  margin-left: -79px;
  margin-top: -71px;
}

.c32 {
  margin-left: -111px;
  margin-top: -15px;
}

.c33 {
  margin-left: 65px;
  margin-top: -43px;
}

.c34 {
  margin-left: 65px;
  margin-top: 13px;
}

.c35 {
  margin-left: -79px;
  margin-top: 41px;
}

.c36 {
  margin-left: 49px;
  margin-top: -71px;
}

.c37 {
  margin-left: 81px;
  margin-top: -15px;
}

.r1 {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.2s;
}

.r2 {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.4s;
}

.r3 {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.6s;
}

.r1 > .hex-brick {
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.2s;
}

.r2 > .hex-brick {
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.4s;
}

.r3 > .hex-brick {
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.6s;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade {
  0% {
    background: #fff176;
  }

  50% {
    background: #90bbbf;
  }

  100% {
    background: #fff176;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes fade {
  0% {
    background: #abf8ff;
  }

  50% {
    background: #389ca6;
  }

  100% {
    background: #abf8ff;
  }
}
</style>
