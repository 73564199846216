import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import HomeLayout from '../views/layout/_home.vue'
import RoomLayout from '../views/layout/_room.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: 'home',
    component: HomeLayout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/home/CollectionsView.vue')
      },
      {
        path: '/collections',
        name: 'collections',
        component: () => import('../views/home/CollectionsView.vue')
      },
      {
        path: '/bot-telegram',
        name: 'bot-telegram',
        component: () => import('../views/BotTelegram.vue')
      },
      {
        path: '/collections/details/:title',
        name: 'details-collections',
        component: () => import('../views/home/CollectionsDetailsView.vue')
      },
      {
        path: '/not-found',
        name: 'not-found',
        component: () => import('../views/NotFound.vue')
      },
      {
        path: '/too-many',
        name: 'too-many',
        component: () => import('../views/Toomany.vue')
      },
      {
        path: '/error',
        name: 'error',
        component: () => import('../views/Error.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/Login.vue')
      },
      {
        path: '/broker',
        name: 'broker',
        component: () => import('../views/broker/Home.vue')
      },
      {
        path: '/broker/list',
        name: 'broker-list',
        component: () => import('../views/broker/List.vue')
      },
      {
        path: '/marketplace',
        name: 'marketplace',
        component: () => import('../views/P2P.vue')
      },
      {
        path: '/airdrop',
        name: 'airdrop',
        component: () => import('../views/airdrop/Airdrop.vue')
      },
      {
        path: '/airdrop/view/:room',
        name: 'airdrop-view',
        component: () => import('../views/airdrop/AirdropDetails.vue')
      },
      {
        path: '/room/history',
        name: 'room-history',
        component: () => import('../views/broker/History.vue')
      },
      {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/Payment.vue')
      },
      {
        path: '/event',
        name: 'event',
        component: () => import('../views/event/Event.vue')
      },
      {
        path: '/event/view/:event',
        name: 'event-view',
        component: () => import('../views/event/EventDetails.vue')
      },
      {
        path: '/account/nfts/:address',
        name: 'account-nfts',
        component: () => import('../views/nft/NFTs.vue')
      },
      {
        path: '/game',
        name: 'collection-game',
        component: () => import('../views/game/Game.vue')
      },
      {
        path: '/ntf/details/:nftId',
        name: 'details-ntf',
        component: () => import('../views/nft/Details.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: 'home',
    component: RoomLayout,
    children: [
      {
        path: '/room',
        name: 'room',
        component: () => import('../views/broker/Room.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router